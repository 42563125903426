import React from "react";
import css from "./Chapbooks.module.scss";
import Nbar from "../../nbar/Nbar";
import img1 from "../../../assets/img/chapbooks/1.png";
import img2 from "../../../assets/img/chapbooks/2.png";
import img3 from "../../../assets/img/chapbooks/3.png";
import img4 from "../../../assets/img/chapbooks/4.png";
import img5 from "../../../assets/img/chapbooks/5.png";
const Chapbooks = () => {
  return (
    <div className={css.wrapper}>
      <div className={css.navArea}>
        <Nbar />
      </div>
      <div className={css.contentArea}>
        <div className={css.content}>
          <div className={css.leftContent}>
            <img src={img1} alt="" />
          </div>
          <div className={css.middleContent}>
            <h1>Chapbooks</h1>
            <h6>
              Chapbooks were street Literature aimed at the poor, prominently in
              the 18th and 19th centuries. They took the format of a folded
              piece of paper that would look a little like an advertising
              leaflet, which is what it would look like today, or an early
              version of a comic book. Later, they would be named Fanzines, or
              ezines, if digital versions of the same. What is interesting about
              chapbooks is even though they have not survived in their original
              form, they have survived in spirit. Many versions of a chapbook
              exists in the modern world available in shops or online They would
              be sold back in the day by hawkers{" "}
              <span style={{ fontWeight: "bold" }}>
                Folded Chapbooks did not have up to 40 pages but were
              </span>{" "}
              folded into around eight parts in the illustrated version as
              shown. Unlike Pamphlets or Broadsheets, the subjects were less
              serious, often fictional or creative in nature. The printed styles
              were primitive - before the times of the professional printed
              press. Chapbooks are early versions of books, comics and novels
              but have not, in the main survived their status as ephemera. They
              existed outside of London and the Uk and the English language
            </h6>
          </div>
          <div className={css.rightContent}>
            <div className={css.rightContentOne}>
              <img src={img2} alt="" />
              <img src={img3} alt="" />
            </div>
            <div className={css.rightContentTwo}>
              {" "}
              <img src={img4} alt="" />
              <img src={img5} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chapbooks;
