const HistoricDataFive = [
  {
    id: 1,
    victim: "1502: Sir James Tyrell",
    crime: "Treason",
    fate: "Beheaded at The Tower",
  },
  {
    id: 2,
    victim: "1510: Edmund Dudley",
    crime: "Treason",
    fate: "Beheaded at The Tower",
  },
  {
    id: 3,
    victim: "1521: Edward Stafford (Duke)",
    crime: "Treason",
    fate: "Beheaded at The Tower",
  },
  {
    id: 4,
    victim: "1534: Elizabeth Barton ",
    crime: "Treason",
    fate: "Burned at The Stake at Smithfield",
  },
  {
    id: 5,
    victim: "1535: Thomas More",
    crime: "Treason",
    fate: "Beheaded at The Tower",
  },
  {
    id: 6,
    victim: "1535: John Fisher",
    crime: "Treason",
    fate: "Beheaded at The Tower",
  },
  {
    id: 7,
    victim: "1535: Robert Lawrence, Augustus Webster/JohnHoughton",
    crime: "Heresy",
    fate: "Hung at Tyburn",
  },
  {
    id: 8,
    victim: "1536: Anne Boleyn",
    crime: "Treason",
    fate: "Beheaded at The Tower",
  },
  {
    id: 9,
    victim: "1536: George Boleyn (Viscount)",
    crime: "Treason",
    fate: "Beheaded at The Tower",
  },
  {
    id: 10,
    victim: "1537: Thomas Darcy (Lord)/ Henry Percy",
    crime: "Rebels",
    fate: "Beheaded at The Tower",
  },
  {
    id: 11,
    victim: "1538: Henry Courtenay",
    crime: "Treason",
    fate: "Beheaded at The Tower",
  },
  {
    id: 12,
    victim: "1540: Thomas Cromwell",
    crime: "Treason",
    fate: "Beheaded at The Tower",
  },
  {
    id: 13,
    victim: "1541: Thomas Culpepper/ Francis Dereham",
    crime: "Treason",
    fate: "Hung, Drawn & Quartered at The Tower",
  },
  {
    id: 14,
    victim: "1541: Margaret Pole (Countess)",
    crime: "Treason",
    fate: "Beheaded at The Tower",
  },
  {
    id: 15,
    victim: "1542: Catherine Howard",
    crime: "Treason",
    fate: "Beheaded at The Tower",
  },
  {
    id: 16,
    victim: "1547: Henry Howard",
    crime: "Treason",
    fate: "Beheaded at The Tower",
  },
  {
    id: 17,
    victim: "1549: Thomas Seymour",
    crime: "Treason",
    fate: "Beheaded at The Tower",
  },
  {
    id: 18,
    victim: "1552: Sir Thomas Arundel",
    crime: "Treason",
    fate: "Beheaded at The Tower",
  },
  {
    id: 19,
    victim: "1553: John Dudley (Duke)",
    crime: "Treason",
    fate: "Beheaded at The Tower",
  },
  {
    id: 20,
    victim: "1554: Thomas Wyatt",
    crime: "Rebel",
    fate: "Beheaded at The Tower",
  },
  {
    id: 21,
    victim: "1554: Anthony Knyvett",
    crime: "Treason",
    fate: "Beheaded at The Tower",
  },
  {
    id: 22,
    victim: "1554: Gilford Dudley (Lord)",
    crime: "Treason",
    fate: "Beheaded at The Tower",
  },
  {
    id: 23,
    victim: "1556: The Stratford Martyrs",
    crime: "Heresy",
    fate: "Burned at The Stake at Smithfield",
  },
  {
    id: 24,
    victim: "1558: William Flower",
    crime: "Heresy",
    fate: "Burned at The Stake at St Margaret's",
  },
  {
    id: 25,
    victim: "1558: John Gates",
    crime: "Treason",
    fate: "Beheaded at The Tower",
  },
  {
    id: 26,
    victim: "1569: Thomas & Christopher Norton",
    crime: "Heresy",
    fate: "Hung, Drawn & Quartered at Tyburn",
  },
  {
    id: 27,
    victim: "1572: Thomas Howard",
    crime: "Treason",
    fate: "Beheaded at The Tower",
  },
  {
    id: 28,
    victim: "1588: Mary Ward",
    crime: "Treason",
    fate: "Scourged, then Hung at Tyburn",
  },
];

export default HistoricDataFive;
