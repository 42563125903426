const HistoryData = [
  {
    id: 1,
    title: "5th Century",
    descrip: "Hanging is introduced as a Capital Punishment",
  },
  {
    id: 2,
    title: "7th Century",
    descrip:
      "Ethelbert introduces a penal system of punishments and fines with percentages going to the Crown",
  },
  {
    id: 3,
    title: "10th Century",
    descrip: "Public Whippings and Mutilations introduced as Punishments",
  },
  {
    id: 4,
    title: "1166",
    descrip:
      "Henry 2nd institutes legal reforms. The Crown and not Clergy decides crime and punishments",
  },
  {
    id: 5,
    title: "1188",
    descrip: "Newgate becomes the main prison for London and Middlesex ",
  },
  {
    id: 6,
    title: "1196",
    descrip: "Tyburn is the site for the first Hanging, Drawing & Quartering",
  },
  {
    id: 7,
    title: "1197",
    descrip:
      "Ethelbert introduces a penal system of punishments and fines with percentages going to the Crown",
  },
  {
    id: 8,
    title: "1236",
    descrip: "A new turret is added to Newgate",
  },
  {
    id: 9,
    title: "1241",
    descrip:
      "Hanging, Drawing & Quartering becomes the penalty for Treason, by law",
  },
  {
    id: 10,
    title: "1241",
    descrip:
      "The use of The Hurdle is introduced to those convicted of Treason",
  },
  {
    id: 11,
    title: "1272",
    descrip:
      "Pressing is introduced as a means of extracting a Plea from a criminal",
  },
  {
    id: 12,
    title: "14th Century",
    descrip: "Heresy is considered treason in Law",
  },
  {
    id: 13,
    title: "1315-1316",
    descrip: "62 Deaths at Newgate Prison are investigated",
  },
  {
    id: 14,
    title: "1351",
    descrip: "Henry 3rd defines Treason in The Treason Act",
  },
  {
    id: 15,
    title: "1381",
    descrip: "Fleet Prison destroyed by Wat Tyler's men",
  },
  {
    id: 16,
    title: "1393",
    descrip:
      "New legislation prevents Gaolers from charging for lamps and beds",
  },
  {
    id: 17,
    title: "1406",
    descrip:
      "A separate tower and section for female prisoners is added to Newgate",
  },
  {
    id: 18,
    title: "1419",
    descrip: "Newgate closes due to Gaol Fever ",
  },
  {
    id: 19,
    title: "1422",
    descrip: "Dick Whittington bequeaths an Improvement Licence for Newgate",
  },
  {
    id: 20,
    title: "1509",
    descrip:
      "Henry 8th introduces Boiling to Death as punishment for Piracy, Rape and Highway Robbery",
  },
  {
    id: 21,
    title: "1509",
    descrip: "Branding a V on Vagrants is now enacted",
  },
  {
    id: 22,
    title: "1509",
    descrip:
      "A loss of the 'writing hand' is brought in as punishment for Seditious Writing",
  },
  {
    id: 23,
    title: "1509",
    descrip: "Whipping Posts emerge in towns and villages",
  },
  {
    id: 24,
    title: "1509",
    descrip: "A loss of Ears is now punishment for not going to church",
  },
  {
    id: 25,
    title: "1533",
    descrip: "'Buggerie' is recognised as a Capital offence",
  },
  {
    id: 26,
    title: "1540",
    descrip: "Henry 8th defines 11 crimes as Capital offences",
  },
  {
    id: 27,
    title: "1540",
    descrip: "Barbers & Surgeons are given 4 bodies a year for research",
  },
  {
    id: 28,
    title: "1542",
    descrip: "Witchcraft is recognised as a capital offence",
  },
  {
    id: 29,
    title: "1547",
    descrip: "Witchcraft as a capital offence is repealed by Edward 6th",
  },
  {
    id: 30,
    title: "1563",
    descrip: "Elizabeth 1st recognises Witchcraft as a Felon",
  },
  {
    id: 31,
    title: "1571",
    descrip:
      "The Triple Tree is erected in Tyburn to deal with multiple hangings",
  },
  {
    id: 32,
    title: "1642-1649",
    descrip: "The English Civil War",
  },
  {
    id: 33,
    title: "1649",
    descrip: "Charles 1st is beheaded at Banqueting House on Whitehall",
  },
  {
    id: 34,
    title: "1649",
    descrip:
      "There is a case of 23 Men and 1 Woman hanged on The Triple tree in one session",
  },
  {
    id: 35,
    title: "1666",
    descrip: "Newgate Prison is destroyed by fire in The Great Fire of London",
  },
  {
    id: 36,
    title: "1666",
    descrip: "Fleet Prison is rebuilt at the expense of The Warden",
  },
  {
    id: 37,
    title: "1671",
    descrip: "It is now a Capital offence to 'lie in wait' to injure or maim",
  },
  {
    id: 38,
    title: "1699",
    descrip: "Shoplifting is now a Capital offence",
  },
  {
    id: 39,
    title: "1713",
    descrip: "Stealing from a Dwelling House is now a Capital offence",
  },
  {
    id: 40,
    title: "1717",
    descrip: "Borough Compter in Tooley St opens",
  },
  {
    id: 41,
    title: "1718",
    descrip: "The Transportation Act is introduced and is in force for 7 years",
  },
  {
    id: 42,
    title: "1723",
    descrip: "The Waltham Black Act makes Poaching a Capital offence",
  },
  {
    id: 43,
    title: "1729",
    descrip: "The Fleet Prison is visited by The Parliamentary Gaols Committee",
  },
  {
    id: 44,
    title: "1751",
    descrip:
      "Those convicted of Murder are hanged within 48 hours and given only bread and water",
  },
  {
    id: 45,
    title: "1752",
    descrip:
      "The Murders Act is introduced to give Anatomists the right to the cadavers of convicted Murderers",
  },
  {
    id: 46,
    title: "1760",
    descrip:
      "The first and only Peer of the Realm to be hanged at Tyburn is Earl Ferrers",
  },
  {
    id: 47,
    title: "1770",
    descrip: "Newgate Prison is improved with reforms",
  },
  {
    id: 48,
    title: "1776",
    descrip:
      "Transportation is on hold due to The American War of Independence",
  },
  {
    id: 49,
    title: "1780",
    descrip: "Newgate is demolished by The Gordon Riots",
  },
  {
    id: 50,
    title: "1782",
    descrip:
      "Newgate rebuilt to the cost of 30k. Two sections divide 'The Commons' from 'The State' prisoners There are subsections for Debtors",
  },
  {
    id: 52,
    title: "1783",
    descrip: "The Highwayman John Austen is hanged at Tyburn",
  },
  {
    id: 53,
    title: "1783",
    descrip: "Newgate has its first public hangings",
  },
  {
    id: 54,
    title: "1788",
    descrip:
      "Transportation resumes but to Australia. Over 162,000 are transported there up to 1868",
  },
  {
    id: 55,
    title: "1789",
    descrip: "The last Burning at the Stake is held at Newgate",
  },
  {
    id: 56,
    title: "1790",
    descrip: "Burning at the Stake for treason is abolished",
  },
  {
    id: 57,
    title: "19th Century",
    descrip: "Public mutilations and maiming is abolished",
  },
  {
    id: 58,
    title: "1810",
    descrip: "In English and Welsh Law there are 222 Capital offences",
  },
  {
    id: 59,
    title: "1814",
    descrip: "The last hanging for Poaching takes place",
  },
  {
    id: 60,
    title: "1814",
    descrip:
      "The Treason Act removes disembowelling and quartering as part of the punishment",
  },
  {
    id: 61,
    title: "1817",
    descrip: "Public Whipping of Women Abolished",
  },
  {
    id: 62,
    title: "1820",
    descrip:
      "The Cato St. Conspirators are the last to be hanged and decapitated",
  },
  {
    id: 63,
    title: "1822",
    descrip: "The last hanging for stealing from a shop",
  },
  {
    id: 64,
    title: "1823",
    descrip:
      "The Punishment of Death Act allows judge's discretion on Capital crimes",
  },
  {
    id: 65,
    title: "1829",
    descrip: "The last hanging for Forgery takes place at Newgate",
  },
  {
    id: 66,
    title: "1830",
    descrip: "The last hanging for Piracy takes place at Execution Dock",
  },
  {
    id: 67,
    title: "1832",
    descrip:
      "The Anatomy Act no longer allows dissections on the cadavers of convicted Murderers",
  },
  {
    id: 68,
    title: "1832",
    descrip:
      "The Punishment of Death Act decreases the number of Capital offences to 60",
  },
  {
    id: 69,
    title: "1832-7",
    descrip: "Robert Peel further reduces the number of Capital offences",
  },
  {
    id: 70,
    title: "1834",
    descrip: "Condemned bodies hanging in chains is abolished",
  },
  {
    id: 71,
    title: "1835",
    descrip: "The last hanging for Sodomy takes place",
  },
  {
    id: 72,
    title: "1836",
    descrip: "The Murders Act is totally repealed",
  },
  {
    id: 73,
    title: "1836",
    descrip:
      "The Prisoner's Council can act as defence for those charged with serious crimes",
  },
  {
    id: 74,
    title: "1836",
    descrip: "The last hangings for Robbery takes place",
  },
  {
    id: 75,
    title: "1837",
    descrip:
      "The Piracy Act only now imposes Death Penalty where there is an intent to Murder",
  },
  {
    id: 76,
    title: "1842",
    descrip:
      "Inmates of Marshelsea Prison , The Fleet and Queens Bench all now reside in The Queen's Bench",
  },
  {
    id: 77,
    title: "1843",
    descrip: "The first legal definition of insanity is considered in defences",
  },
  {
    id: 78,
    title: "1844",
    descrip: "The Queen's Bench is sold",
  },
  {
    id: 79,
    title: "1846",
    descrip: "The Queen's Bench is pulled down",
  },
  {
    id: 80,
    title: "1850",
    descrip:
      "It is claimed that the real site of Tyburn Gallows is 49 Connaught Square",
  },
  {
    id: 81,
    title: "1853",
    descrip:
      "The Penal Servitude Act introduces sentencing in prison as punishment",
  },
  {
    id: 82,
    title: "1855",
    descrip: "Borough Compter Demolished",
  },
  {
    id: 83,
    title: "1856",
    descrip:
      "The pinioning of the legs of prisoners to be hanged is introduced",
  },
  {
    id: 84,
    title: "1861",
    descrip: "The Home Secretary has the powers to reprieve the Death Sentence",
  },
  {
    id: 85,
    title: "1862",
    descrip: "Public Whipping Abolished",
  },
  {
    id: 86,
    title: "1864-66",
    descrip: "A Royal Commission recommends the end of The Death Penalty",
  },
  {
    id: 87,
    title: "1868",
    descrip: "The last public hanging at Newgate",
  },
  {
    id: 88,
    title: "1868",
    descrip: "The first private hangings take place",
  },
  {
    id: 89,
    title: "1869",
    descrip: "The Debtor's Act abolishes prison for debt",
  },
  {
    id: 90,
    title: "1871",
    descrip: "The Mug Shot is introduced",
  },
  {
    id: 91,
    title: "1872",
    descrip: "The Long Drop as hanging method is introduced",
  },
  {
    id: 92,
    title: "1877",
    descrip:
      "The Prisons Act now brings prisons under the control of the Home Office",
  },
  {
    id: 93,
    title: "1878",
    descrip: "The first hanging at Wandsworth",
  },
  {
    id: 94,
    title: "1884",
    descrip:
      "The Criminal Lunatics Act means that there is an examination of those condemned to ascertain sanity",
  },
  {
    id: 95,
    title: "1901",
    descrip:
      "The tolling of the prison bell now happens just once after an execution takes place",
  },
  {
    id: 96,
    title: "1902",
    descrip:
      "Newgate is closed and then demolished. 1120 men and 49 women have been executed there since the switch from Tyburn in 1789, including 3 burnings",
  },
  {
    id: 97,
    title: "1902",
    descrip:
      "Male executions now take place in Pentonville, female at Holloway",
  },
  {
    id: 98,
    title: "1902",
    descrip: "Holloway becomes the first female only prison",
  },
  {
    id: 99,
    title: "1903",
    descrip: "First hanging at Holloway",
  },
  {
    id: 100,
    title: "1905",
    descrip: "First instance of fingerprint used in evidence",
  },
  {
    id: 101,
    title: "1914",
    descrip: "The oldest Londoner to be hanged is 71, a Leytonstone grocer",
  },
  {
    id: 102,
    title: "1908",
    descrip: "The Children's Act means under 16s no longer hanged",
  },
  {
    id: 103,
    title: "1922",
    descrip:
      "Infanticide Act: the killing of a new born is no longer a Capital offence",
  },
  {
    id: 104,
    title: "1931",
    descrip:
      "Sentence of Death for Expectant Mothers. Mothers no longer to be hanged straight after giving birth",
  },
  {
    id: 105,
    title: "1941",
    descrip:
      "Last man to be executed via Firing Squad takes place at the Tower of London",
  },
  {
    id: 106,
    title: "1946",
    descrip: "The last hanging for High Treason",
  },
  {
    id: 107,
    title: "1953",
    descrip: "The last hanging under Military Jurisdiction",
  },
  {
    id: 108,
    title: "1954",
    descrip: "The last double hanging at Pentonville",
  },
  {
    id: 109,
    title: "1955",
    descrip: "The last woman to be hanged, Ruth Ellis at Holloway",
  },
  {
    id: 110,
    title: "1957",
    descrip:
      "The Homicide Act limits The Death Penalty to five categories of murder",
  },
  {
    id: 111,
    title: "1957",
    descrip: "The defence of Diminished Responsibility comes into English Law",
  },
  {
    id: 112,
    title: "1960",
    descrip: "The last teenager to be hanged",
  },
  {
    id: 113,
    title: "1964",
    descrip: "The last hangings take place",
  },
  {
    id: 114,
    title: "1965",
    descrip: "The Death penalty now changed into life sentence",
  },
  {
    id: 115,
    title: "1965",
    descrip: "The Abolition of The Death Penalty passed",
  },
  {
    id: 116,
    title: "1971",
    descrip: "Arson in The Royal Docklands ceases to be a Capital offenceaaa",
  },
  {
    id: 117,
    title: "1994",
    descrip:
      "A House of Commons vote to reintroduce The Death Penalty is quashed 403 votes to 159",
  },
  {
    id: 118,
    title: "1998",
    descrip:
      "The Death Penalty abolished for crimes under Military Jurisdiction",
  },
  {
    id: 119,
    title: "1998",
    descrip:
      "European Convention on Human Rights incorporated into British Law",
  },
  {
    id: 120,
    title: "1998",
    descrip:
      "High treason and Piracy removed from Statute as Capital offences. Death Penalty ends",
  },
  {
    id: 121,
    title: "1999",
    descrip: "The 6th Protocol on Human Rights is signed by UK",
  },
];

export default HistoryData;
