import React from "react";
import css from "./Campaigns.module.scss";
// import { AiOutlineHome } from "react-icons/ai";
// import { useNavigate } from "react-router-dom";
import img1 from "../../../assets/img/campaigns/pub.png";
import img2 from "../../../assets/img/campaigns/crafty.png";
import img3 from "../../../assets/img/campaigns/bespoke.png";
import Nbar from "../../nbar/Nbar";
import Avijit from "../Avijit/Avijit";

const Campaigns = () => {
  // const navigate = useNavigate();

  // const navigateHome = () => {
  //   // 👇️ navigate to /
  //   navigate("/campaigns");
  // };
  return (
    <div className={css.wrapper}>
      <div className={css.navArea}>
        <Nbar />
      </div>
      <div className={css.textArea}>
        <h1>Campaigns</h1>
      </div>
      <div className={css.contentArea}>
        <div className={css.mainContent}>
          <div
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
            className={css.content}
          >
            <div data-aos="fade-up" className={css.contentImage}>
              <img src={img1} alt="" />
            </div>
            <div data-aos="fade-up" className={css.contentText}>
              <h1>Pub Talks</h1>
              <h6>
                There is an impetus to have empty function rooms in pubs in
                London and other major cities filled with actors talking to an
                eager customer base about either an event in history (according
                to calendar), or about the history of the pub itself. This
                impetus encompasses not just the pub, but hotel, trades
                association and club. For info contact
                info@tripletreetourslondon.com with Pub Talks in heading
              </h6>
            </div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
            className={css.content}
          >
            <div data-aos="fade-up" className={css.contentImage}>
              <img src={img2} alt="" />
            </div>
            <div data-aos="fade-up" className={css.contentText}>
              <h1>Crafty Past</h1>
              <h6>
                This is a YouTube channel and an aspect of the business aimed at
                Collective Collaboration. There are many opportunities to make
                goods that have an historic context and this part of the
                business is a campaign for small business owners to send in a
                pattern, template or formula that can be downloaded at a small
                cost. The goods promoted should have an accompanying
                demonstration video
              </h6>
            </div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
            className={css.content}
          >
            <div data-aos="fade-up" className={css.contentImage}>
              <img src={img3} alt="" />
            </div>
            <div data-aos="fade-up" className={css.contentText}>
              <h1>Bespoke Historic Gifts</h1>
              <h6>
                There are plans to create software to make Wanted Posters,
                Chapbooks and Murder Ballads - in keeping with the goods that
                were sold by the public during public hangings at Tyburn, West
                London for hundreds of years. These can be ordered in advance{" "}
                <br />
                <span style={{ color: "red" }}> however Contact:</span>{" "}
                <span style={{ color: "#f17305", fontWeight: "700" }}>
                  gifts@tripletreetourslondon.com
                </span>
              </h6>
            </div>
          </div>
        </div>
      </div>
      <Avijit />
    </div>
  );
};

export default Campaigns;
