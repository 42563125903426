import React from "react";
import css from "./Map2.module.scss";
import img1 from "../../../../assets/img/maps/map2img1.png";
import img2 from "../../../../assets/img/maps/map2img2.png";
import img3 from "../../../../assets/img/maps/map2img3.png";
import img4 from "../../../../assets/img/maps/map2img4.png";
import img5 from "../../../../assets/img/maps/map2img5.png";
import img6 from "../../../../assets/img/maps/map2img6.png";
import img7 from "../../../../assets/img/maps/map2img7.png";
import img8 from "../../../../assets/img/maps/map2img8.png";
const Map2 = () => {
  const handleImageClick = () => {
    // Replace the URL with the actual Etsy account URL
    const etsyAccountUrl = 'https://www.etsy.com/uk/shop/Tripletreetours';
    window.open(etsyAccountUrl, '_blank');
  };
  return (
    <div className={css.wrapper}>
      <div className={css.content}>
        <div className={css.images}>
          <div className={css.image}>
            <img src={img1} alt="" />
            <h5>London Aerial 2015: City View</h5>
          </div>
          <div className={css.image}>
            <img src={img2} alt="" />
            <h5>London Aerial 2015: Lambeth View</h5>
          </div>
          <div className={css.image}>
            <img src={img3} alt="" /> <h5>London Aerial 2015: Parks View</h5>
          </div>
          <div className={css.image}>
            <img src={img4} alt="" /> <h5>London Aerial 2015: South View</h5>
          </div>
          <div className={css.image}>
            <img src={img5} alt="" /> <h5>London Aerial 1300: City & River</h5>
          </div>
          <div className={css.image}>
            <img src={img6} alt="" />{" "}
            <h5>London Aerial 1300: City & Westminster/Lundenwic</h5>
          </div>
          <div className={css.image}>
            <img src={img7} alt="London Aerial 1300: City & Tower" />{" "}
            <h5>London Aerial 1300: City & Tower</h5>
          </div>
          <div className={css.imageBtn}>
            <img src={img8} alt="Etsy Account" onClick={handleImageClick} />
          </div>
        </div>
        <div className={css.text}>
          <h1>Aerial Views of London: Ancient & Modern</h1>
          <h6>
            These maps illustrate the Ancient & Modern worlds of London as a
            major capital city, taking it from early Medieval to 21st Century.
            The City of London has developed from a square mile with six sentry
            points as illustrated, to what seems now like a mini Manhattan. The
            development of the city is constant: the modern pictures as
            presented are now out of date; however they do present a good idea
            of the development that never, ever stops. London, at the time of
            The Great Fire of London in 1666 still had the shape of London in
            45AD. It has developed more in 20 years than it did between the
            Roman Invasion and Stuart London 1600 years later.
          </h6>
        </div>
      </div>
    </div>
  );
};

export default Map2;
