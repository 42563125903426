import React from "react";
import css from "./StuffOnSex.module.scss";

import StuffOnSexData from "./StuffOnSexData";
import Nbar from "../../nbar/Nbar";
const StuffOnSex = () => {
  return (
    <div className={css.wrapper}>
      <div className={css.homeNavigate}>
        <Nbar />
      </div>
      <div className={css.header}></div>

      <div className={css.body}>
        <div className={css.tableContent}>
          <table className={css.table}>
            <thead>
              <tr>
                <th>Character</th>
                <th>Role</th>
                <th>Date</th>
                <th>Area of London</th>
                <th>Significance</th>
              </tr>
            </thead>
            <tbody>
              {StuffOnSexData.map((StuffOnSexData) => (
                <tr key={StuffOnSexData.id}>
                  <td
                    style={{
                      fontWeight: "800",
                      padding: ".5rem",
                      border: "1px solid black",
                    }}
                  >
                    {StuffOnSexData.character}
                  </td>
                  <td style={{ padding: ".5rem", border: "1px solid black" }}>
                    {StuffOnSexData.role}
                  </td>
                  <td style={{ border: "1px solid black" }}>
                    {StuffOnSexData.date}
                  </td>
                  <td style={{ padding: ".5rem", border: "1px solid black" }}>
                    {StuffOnSexData.areaoflondon}
                  </td>
                  <td style={{ padding: ".5rem", border: "1px solid black" }}>
                    {StuffOnSexData.significance}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default StuffOnSex;
