import React from "react";
import "./App.css";
import Home from "./components/home/Home";

import LearningDevelopment from "./components/pages/LearningDevelopment";
import Campaigns from "./components/pages/campaigns/Campaigns";
import Book from "./components/pages/book/Book";
import TsCs from "./components/pages/tscs/TsCs";
import HistoricTimeline from "./components/pages/historicTimeline/HistoricTimeline";
import ExecutorsTorturers from "./components/pages/executorsTorturers/ExecutorsTorturers";
import Pamphlets from "./components/pages/pamphlets/Pamphlets";
import StuffOnSex from "./components/pages/stuffonsex/StuffOnSex";
import HistoricCase from "./components/pages/historicCase/HistoricCase";
import ImmerTours from "./components/pages/immersiveTours/ImmerTours";
import Lgbtqia from "./components/pages/lgbtqia/Lgbtqia";
import TyburnTourPage from "./components/pages/tyburn/TyburnTourPage";
import SinSex from "./components/pages/sinSex/SinSex";
import FoodDrink from "./components/pages/foodDrink/FoodDrink";
import GreatFireLondon from "./components/pages/greatFireLondon/GreatFireLondon";
import AlesAlehouses from "./components/pages/alesAlehouses/AlesAlehouses";
import AlesHouses from "./components/pages/alesAlehouses/AlesHouses";
import ImmersiveTourOne from "./components/pages/immersiveTours/ImmersiveTourPages/ImmersiveTourOne";
import ImmersiveTourTwo from "./components/pages/immersiveTours/ImmersiveTourPages/ImmersiveTourTwo";
import ImmersiveTourThree from "./components/pages/immersiveTours/ImmersiveTourPages/ImmersiveTourThree";
import Broadsides from "./components/pages/broadsides/Broadsides";
import Ballads from "./components/pages/ballads/Ballads";
import Chapbooks from "./components/pages/chapbooks/Chapbooks";
import Gift from "./components/pages/gift/Gift";
import Crafts from "./components/pages/crafts/Crafts";
import Posters from "./components/pages/posters/Posters";
import Maps from "./components/pages/maps/Maps";
import Map2 from "./components/pages/maps/mapPages/Map2";
import Checkout from "./components/pages/order/Checkout";
import TourRoutes from "./components/pages/tourRoutes/TourRoutes";
import { Routes, Route } from "react-router-dom";
import Payment from "./components/pages/payment/Payment";
import AvijitDetails from "./components/pages/Avijit/AvijitDetails";
import { Helmet } from "react-helmet";
function App() {
  return (
    <>
      <Helmet>
        <meta
          name="keywords"
          content="TripleTreeToursLondon, TrippleTree, Bespoke Historic Gifts,Pub Talks,Historic Timeline,Executors & Torturers,Broadsides,Ballads,Historic Case Histories,Pamphlets,Chapbooks,Stuff on Sex,Sin & Sex inin London"
        />
      </Helmet>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/learning&development" element={<LearningDevelopment />} />
        <Route path="/campaigns" element={<Campaigns />} />
        <Route path="/resources" element={<Book />} />
        <Route path="/Ts-&-Cs" element={<TsCs />} />
        <Route path="/historicTimeline" element={<HistoricTimeline />} />
        <Route path="/executorsTorturers" element={<ExecutorsTorturers />} />
        <Route path="/pamphlets" element={<Pamphlets />} />
        <Route path="/stuff-on-sex" element={<StuffOnSex />} />
        <Route path="/historic-case" element={<HistoricCase />} />
        <Route path="/immersive-tours" element={<ImmerTours />} />
        <Route path="/lgbtqia-tour-page" element={<Lgbtqia />} />
        <Route path="/tyburn-tour-page" element={<TyburnTourPage />} />
        <Route path="/sin-sex-in-london" element={<SinSex />} />
        <Route path="/food-drinks-tour" element={<FoodDrink />} />
        <Route path="/great-fire-of-london" element={<GreatFireLondon />} />
        <Route path="/ales-coffeehouse-pubs" element={<AlesAlehouses />} />
        <Route path="/immersive-tour-1" element={<ImmersiveTourOne />} />
        <Route path="/immersive-tour-2" element={<ImmersiveTourTwo />} />
        <Route path="/immersive-tour-3" element={<ImmersiveTourThree />} />
        <Route path="/broadsides" element={<Broadsides />} />
        <Route path="/ballads-&-murder-ballads" element={<Ballads />} />
        <Route path="/chapbooks" element={<Chapbooks />} />
        <Route path="/gifts" element={<Gift />} />
        <Route path="/crafts" element={<Crafts />} />
        <Route path="/maps" element={<Maps />} />
        <Route path="/posters" element={<Posters />} />
        <Route path="/ales-houses" element={<AlesHouses />} />
        <Route path="/map2" element={<Map2 />} />
        <Route path="/avijit-contacts" element={<AvijitDetails />} />

        <Route path="payment" element={<Payment />} />
        <Route path="checkout"  element={<Checkout />} />
        <Route path="tour-routes"  element={<TourRoutes />} />
      </Routes>
    </>
  );
}

export default App;
