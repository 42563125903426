const HistoricDataSix = [
  {
    id: 1,
    victim: "1902: George Wolfe",
    crime: "Murder",
    fate: "Hung at Newgate",
  },
  {
    id: 2,
    victim: "1903: Amelia Sach",
    crime: "Child Killing",
    fate: "Hung at Newgate",
  },
  {
    id: 3,
    victim: "1907:Richard Clifford Brinkley",
    crime: "Poisoner",
    fate: "Hung at Wandsworth",
  },
  {
    id: 4,
    victim: "1910: Hawley Harvey Crippen",
    crime: "Murder",
    fate: "Hung at Pentonville",
  },
  {
    id: 5,
    victim: "1914: Carl Hans Lody",
    crime: "Espionage",
    fate: "Shot by Firing Squad at The Tower",
  },
  {
    id: 6,
    victim: "1915: Haike Janssen",
    crime: "Espionage",
    fate: "Shot by Firing Squad at The Tower",
  },
  {
    id: 7,
    victim: "1915: Willem Ross",
    crime: "Espionage",
    fate: "Shot by Firing Squad at The Tower",
  },
  {
    id: 8,
    victim: "1919: Frank George Warren",
    crime: "Murder",
    fate: "Hung at Pentonville",
  },
  {
    id: 9,
    victim: "1926: Eugene De Vere",
    crime: "Murder",
    fate: "Hung at Pentonville",
  },
  {
    id: 10,
    victim: "1929: William John Holmyard",
    crime: "Murder",
    fate: "Hung at Pentonville",
  },
  {
    id: 11,
    victim: "1936: Frederick Field",
    crime: "Murder",
    fate: "Hung at Wandsworth",
  },
  {
    id: 12,
    victim: "1939: William Thomas Butler",
    crime: "Murder/Robbery",
    fate: "Hung at Wandsworth",
  },
  {
    id: 13,
    victim: "1941: Josef Jakobs",
    crime: "Espionage",
    fate: "Shot by Firing Squad at The Tower",
  },
  {
    id: 14,
    victim: "1943: Charles Arthur Raymond",
    crime: "Murder",
    fate: "Hung at Wandsworth",
  },
  {
    id: 15,
    victim: "1947: William Frederick Reynolds",
    crime: "Murder",
    fate: "Hung at Pentonville",
  },
  {
    id: 16,
    victim: "1950: Timothy John Evans",
    crime: "Murder",
    fate: "Hung at Pentonville",
  },
  {
    id: 17,
    victim: "1953: Derek Bentley",
    crime: "Murder/Robbery",
    fate: "Hung at Wandsworth",
  },
  {
    id: 18,
    victim: "1955: Ruth Ellis",
    crime: "Murder",
    fate: "Hung at Holloway",
  },
  {
    id: 19,
    victim: "1960: Norman James Harris",
    crime: "Murder",
    fate: "Hung at Pentonville",
  },
];

export default HistoricDataSix;
