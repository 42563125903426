const ExecutorsData3 = [
  {
    id: 1,
    title: "George Smith",
    descrip: "1840 - 1872",
  },
  {
    id: 2,
    title: "William Calcraft",
    descrip: "1829 - 1874",
  },
  {
    id: 3,
    title: "William Marwood",
    descrip: "1872 - 1883",
  },
  {
    id: 4,
    title: "Bartholomew Binns",
    descrip: "1883 - 1884",
  },
  {
    id: 5,
    title: "James Billington",
    descrip: "1884 - 1901",
  },
  {
    id: 6,
    title: "Henry Pierrepoint",
    descrip: "1900 - 1910",
  },
  {
    id: 7,
    title: "William Willis",
    descrip: "1906 - 1926",
  },
  {
    id: 8,
    title: "Robert O Baxter",
    descrip: "1915 - 1935",
  },
  {
    id: 9,
    title: "Alfred Allen ",
    descrip: "1928 - 1937",
  },
  {
    id: 10,
    title: "Thomas M Phillips",
    descrip: "1918 - 1941",
  },
  {
    id: 10,
    title: "Stanley W Cross",
    descrip: "1932 - 1941",
  },
  {
    id: 10,
    title: "Albert Pierrepoint",
    descrip: "1932 - 1956",
  },
];
export default ExecutorsData3;
