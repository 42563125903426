import React from "react";
import css from "./Posters.module.scss";
import img1 from "../../../assets/img/posters/img1.png";
import img2 from "../../../assets/img/posters/img2.png";
import img3 from "../../../assets/img/posters/img3.png";
import Nbar from "../../nbar/Nbar";
const Posters = () => {
  return (
    <div className={css.wrapper}>
      <div className={css.navArea}>
        <Nbar />
      </div>
      <div className={css.mainContent}>
        <div className={css.upperContent}>
          <div className={css.content}>
            <div className={css.image}>
              <div>
                {" "}
                <img src={img1} alt="" />
              </div>
              <h4>
                Billboard Advertising made from photograph and merged with
                advert from 1880s
              </h4>
            </div>
            <div className={css.image}>
              <div>
                {" "}
                <img src={img2} alt="" />
              </div>
              <h4>
                Photo merged with infamous portrait of Jack Shepherd, the
                escapologist of the 1700s
              </h4>
            </div>
            <div className={css.image}>
              <div>
                <img src={img3} alt="" />
              </div>
              <h4>
                Wanted poster covering the two storylines of highwayman wanted
                and barmaid pitching for body parts
              </h4>
            </div>
          </div>
        </div>
        <div className={css.downContent}>
          <div>
            <h1>POSTERS, CHAPBOOKS, BROADSHEETS & BALLADS</h1>
            <h6>
              There are plans afoot to develop software to recreate the Street
              Literature of the past, sold by street hawkers in the 16th, 17th,
              18th and 19th centuries. Looking at the RESOURCES section of this
              site, there are plenty of types to go on. The software will follow
              these stages:
            </h6>
            <h6 style={{ fontWeight: "bold", color: "#911515" }}>
              {" "}
              1. The buyer chooses the type of Street Literature
            </h6>
            <h6 style={{ fontWeight: "bold", color: "#911515" }}>
              2. The era is chosen
            </h6>
            <h6 style={{ fontWeight: "bold", color: "#911515" }}>
              3. The character type is chosen
            </h6>
            <h6 style={{ fontWeight: "bold", color: "#911515" }}>
              4. The storyline is chosen
            </h6>
            <h6 style={{ fontWeight: "bold", color: "#911515" }}>
              5. The photograph is uploaded The Price will depend on the
              complexity of the works and it will take around two weeks and be
              delivered in a completed jpeg or Pdf to the customer.
            </h6>
            <h6>These can be ordered and produced now at an agreed cost:</h6>
          </div>
          <h6 style={{ fontWeight: "bold", paddingTop: "1rem" }}>
            posters@tripletreetourslondon.com{" "}
          </h6>
        </div>
      </div>
    </div>
  );
};

export default Posters;
