import React from "react";
import css from "./SinSex.module.scss";
import img1 from "../../../assets/img/Immersive/sinsex1.png";
import img2 from "../../../assets/img/Immersive/sinsex2.png";
import ImmerNav from "../immersiveTours/ImmerNav"; 
import { useNavigate } from "react-router-dom";
import book from "../../../../src/assets/img/bk.png";

const SinSex = () => {
  const navigate = useNavigate();
  const navigateOrderPage = () => {
    navigate("/checkout");
  };
  return (
    <div className={css.wrapper}>
      <div className={css.navArea}>
        <ImmerNav />
      </div>
      <div className={css.body}>
        <div className={css.upperBody}>
          <div className={css.upperBodyLeft}>
            <img src={img1} alt="" />
          </div>
          <div className={css.upperBodyRight}>
            <div>
              <h1>Triple Tree Tours </h1>
              <h2>Sin, Sex & Vice in London (45AD Onwards)</h2>
            </div>
            <div>
              <h6>
                <span style={{ fontWeight: "bold" }}>Description: </span>{" "}
                <span style={{ textDecoration: "underline" }}>Adult Only </span>{" "}
                tour of the history of Sin, Sex and Vice in London from the
                Roman Invasion onwards. There is no other walking tour like this
                in the world. Here we take the participant through the history
                of prostitution on the Southbank, the Inns of London, medieval
                values, Victorian BDSM, pornography and sex in print. We link
                theatre land and procuring. We embark on the sex history of
                Covent Garden....We end the walk at a traditional London pub
                where we do a Q&A and sex in Soho. The tour is 5 hours. Meeting
                Place: The Globe, Southbank
              </h6>
            </div>
          </div>
        </div>

        <div className={css.middleBody}>
          <div className={css.middleLeft}>
            <img src={img2} alt="" />
          </div>
          <div className={css.middleMiddle}>
            <div className={css.text1}>
              <h1>What is NOT Included</h1>
              <h6> Food & Drink</h6> <h6>Waterproofs</h6>{" "}
              <h6> Refunds or Tips</h6>
            </div>
            <div className={css.text2}>
              <h1>What IS INCLUDED</h1>
              <h6>Costumed Professional Guide</h6>
              <h6> Free Goods & Services from Triple Tree Tours </h6>
              <h6>Lifetime Access to Guide & After Sales Service </h6>
              <h6>Q&A for duration of tour</h6>
            </div>
          </div>
          <div className={css.middleRight}>
            <img src={img2} alt="" />
          </div>
        </div>

        <div className={css.bottomBody}>
          <div className={css.bottomBodyLeft}>
            <span>Dates for 2023</span>
            <h4 style={{ fontWeight: "bold" }}>May: 6, 13, 14, 20</h4>
            <h4 style={{ fontWeight: "bold" }}>June: 3, 10, 17, 25</h4>
            <h4 style={{ fontWeight: "bold" }}>July: 8, 15, 22, 29</h4>
            <h4 style={{ fontWeight: "bold" }}>August: 12, 20, 26</h4>
            <h4 style={{ fontWeight: "bold" }}>September: 9, 10, 23, 24</h4>
            <h4 style={{ fontWeight: "bold" }}>October: 7, 8, 14, 21</h4>
          </div>
          <div className={css.bottomBodyRight}>
            <h4 style={{ fontWeight: "bold" }}>
              BOOK NOW TO AVOID DISAPPOINTMENT
            </h4>
            <h4 style={{ fontWeight: "bold" }}>£120 ONLY</h4>
            <h4 style={{ fontWeight: "bold" }}>5 HOURS</h4>
            <h4>Friday Start: 5pm</h4>
            <h4>Sat/Sun Start 1pm</h4>
            <h4 style={{ fontWeight: "bold" }}>Date</h4>
            <h4 style={{ fontWeight: "bold" }}>Number of Participants</h4>
            <div className={css.checkoutArea}>
              <div className={css.checkout}>
                <div className={css.paymentBtn}>
                  <img style={{ width: '10rem' }} src={book} alt="book now" onClick={navigateOrderPage} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SinSex;
