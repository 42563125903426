import React from 'react'
import ImmerNav from '../immersiveTours/ImmerNav';
import css from './TourRoutes.module.scss';
import ImmersiveTourOne from '../immersiveTours/ImmersiveTourPages/ImmersiveTourOne';
import ImmersiveTourTwo from '../immersiveTours/ImmersiveTourPages/ImmersiveTourTwo';
import ImmersiveTourThree from '../immersiveTours/ImmersiveTourPages/ImmersiveTourThree';
const TourRoutes = () => {
  return (
    <div className={css.wrapper}>
      <div className={css.navbar}>
        <ImmerNav />
      </div>
      <div className={css.container}>
        <div className={css.firstTour}>
          <ImmersiveTourOne />
        </div>
        <div className={css.secondTour}>
          <ImmersiveTourTwo />
        </div>
        <div className={css.tourThree}>
          <ImmersiveTourThree />
        </div>
      </div>
    </div>
  )
}

export default TourRoutes