import React, { useState } from "react";
import css from "./Maps.module.scss";
import Nbar from "../../nbar/Nbar";
import { Carousel } from "react-bootstrap";
import Map2 from "./mapPages/Map2";
import Map3 from "./mapPages/Map3";
import Map4 from "./mapPages/Map4";
import Map5 from "./mapPages/Map5";
const Maps = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <div className={css.wrapper}>
      <div className={css.navArea}>
        <Nbar />
      </div>
      <div className={css.header}>
        <h1>Triple Tree Tours Maps</h1>
        <h6>
          The 5 Tours available from Triple Tree Tours cover the period from the
          Roman Invasion onwards. So do the maps. What is the most frustrating
          part of guiding through London is the business of trying to describe
          and event or person from the past in an ever increasingly over
          developed city. The ever changing nature of London makes recreating
          situations and areas where they happened almost impossible: this
          aspect of tour guiding has been the inspiration behind creating these
          maps. They are the size and shape as described, are Download To Own
          and available on www.etsy.com/shop/tripletreetours Those that pay for
          a tour and are successfully hosted, have lifetime access to the goods
          available on this site
        </h6>
      </div>
      <div className={css.body}>
        <div className={css.bodyContent}>
          <Carousel activeIndex={index} onSelect={handleSelect} variant="dark">
            <Carousel.Item>
              <Map2 />
            </Carousel.Item>
            <Carousel.Item>
              <Map3 />
            </Carousel.Item>
            <Carousel.Item>
              <Map4 />
            </Carousel.Item>
            <Carousel.Item>
              <Map5 />
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default Maps;
