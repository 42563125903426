import React from "react";
import { useNavigate } from "react-router-dom";
import css from "./TyburnTourPage.module.scss";
import img1 from "../../../assets/img/Immersive/tyburn.png";
import img2 from "../../../assets/img/Immersive/tyburn1.png";
import img3 from "../../../assets/img/Immersive/tyburn2.png";
import ImmerNav from "../immersiveTours/ImmerNav";
import book from "../../../../src/assets/img/bk.png";
// import Payment from "../payment/Payment";
import "react-datepicker/dist/react-datepicker.css";
const TyburnTourPage = () => {
  const navigate = useNavigate();
  const navigateOrderPage = () => {
    navigate("/checkout");
  };
  return (
    <div className={css.wrapper}>
      <div className={css.navArea}>
        <ImmerNav />
      </div>
      <div className={css.body}>
        <div className={css.upperBody}>
          <div className={css.upperBodyLeft}>
            <img src={img1} alt="img" />
          </div>
          <div className={css.upperBodyRight}>
            <div>
              <h1>Triple Tree Tours </h1>{" "}
              <h2>Tyburn: Executions, Punishments & Death Walking Tour</h2>
            </div>
            <div>
              <h6>
                <span style={{ fontWeight: "bold" }}>Description:</span>{" "}
                <span style={{ textDecoration: "underline" }}>Adult Only </span>{" "}
                and very explicit account of the nasty side of London state
                endorsed cruelty. This includes deaths, tortures, mutilations
                and of course executions. The physical walk takes the
                participants through the journey of a condemned victim from
                prison to hanging place: for the duration of this trip, we run
                through the history of human cruelty as spectacle and
                entertainment. We end the walk at a traditional London pub. The
                tour is 3.5 hours. Meeting Place: St. Pauls
              </h6>
            </div>
          </div>
        </div>

        <div className={css.middleBody}>
          <div className={css.middleLeft}>
            <img src={img2} alt="img" />
          </div>
          <div className={css.middleMiddle}>
            <div className={css.text1}>
              <h1>What is NOT Included</h1>
              <h6> Food & Drink</h6> <h6>Waterproofs</h6>{" "}
              <h6> Refunds or Tips</h6>
            </div>
            <div className={css.text2}>
              <h1>What IS INCLUDED</h1>
              <h6>Costumed Professional Guide</h6>
              <h6> Free Goods & Services from Triple Tree Tours </h6>
              <h6>Lifetime Access to Guide & After Sales Service </h6>
              <h6>Q&A for duration of tour</h6>
            </div>
          </div>
          <div className={css.middleRight}>
            <img src={img3} alt="" />
          </div>
        </div>

        <div className={css.bottomBody}>
          <div className={css.bottomBodyLeft}>
            <span>Dates for 2023</span>
            <h4 style={{ fontWeight: "bold" }}>May: 27</h4>
            <h4 style={{ fontWeight: "bold" }}>June: 9, 30</h4>
            <h4 style={{ fontWeight: "bold" }}>July: 7, 16, 30</h4>
            <h4 style={{ fontWeight: "bold" }}>August: 5, 27</h4>
            <h4 style={{ fontWeight: "bold" }}>September: 1, 17</h4>
            <h4 style={{ fontWeight: "bold" }}>October: 20, 28</h4>
          </div>
          <div className={css.bottomBodyRight}>
            <h4 style={{ fontWeight: "bold" }}>
              BOOK NOW TO AVOID DISAPPOINTMENT
            </h4>
            <h4 style={{ fontWeight: "bold" }}>£90.00 ONLY</h4>
            <h4 style={{ fontWeight: "bold" }}>3.5 HOURS</h4>
            <h4>Friday Start: 4pm</h4>
            <h4>Sat/Sun Start 1pm</h4>

            <div className={css.checkoutArea}>
              <div className={css.checkout}>
                <div className={css.paymentBtn}>
                  <img style={{width:'10rem'}} src={book} alt="book now" onClick={navigateOrderPage} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TyburnTourPage;
