import React, { useState } from "react";

const PaymentFunction = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const handlePaymentButtonClick = () => {
    setPopupOpen(true);
  };
  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setPopupOpen(false);
    // Process the selected payment method here
    console.log(`Processing payment with ${option}`);
  };
  return (
    <div>
      <button onClick={handlePaymentButtonClick}>Payment</button>
      {isPopupOpen && (
        <div className="popup">
          <button onClick={() => handleOptionSelect("PayPal")}>
            Pay with PayPal
          </button>
          <button onClick={() => handleOptionSelect("Stripe")}>
            Pay with Stripe
          </button>
        </div>
      )}
    </div>
  );
};

export default PaymentFunction;
