const ExecutorsData = [
  {
    id: 1,
    title: "Cratwell",
    descrip: "1538",
  },
  {
    id: 2,
    title: "Stump Leg",
    descrip: "1556",
  },
  {
    id: 3,
    title: "Bull",
    descrip: "1587 - 1601",
  },
  {
    id: 4,
    title: "Thomas Derrick",
    descrip: "1601 - 1616",
  },
  {
    id: 5,
    title: "Gregory Brandon",
    descrip: "1616 - 1640",
  },
  {
    id: 6,
    title: "Richard Brandon",
    descrip: "1640 - 1649",
  },
  {
    id: 7,
    title: "William Lewen",
    descrip: "1649",
  },
  {
    id: 8,
    title: "Edward Dun",
    descrip: "1649 - 1663",
  },
  {
    id: 9,
    title: "Jack Ketch",
    descrip: "1663 - 1683",
  },
  {
    id: 10,
    title: "Paskah Rose",
    descrip: "1686",
  },
];
export default ExecutorsData;
