import React from "react";
import css from "./Gift.module.scss";
import { useNavigate } from "react-router-dom";
import Nbar from "../../nbar/Nbar";
const Gift = () => {
  const navigate = useNavigate();
  const navigateCrafts = () => {
    navigate("/crafts");
  };
  const navigateMaps = () => {
    navigate("/maps");
  };
  const navigatePosters = () => {
    navigate("/posters");
  };
  return (
    <div className={css.wrapper}>
      <div className={css.navArea}>
        <Nbar />
      </div>
      <div className={css.body}>
        <div className={css.content} onClick={navigateCrafts}>
          <div className={css.contentText}>
            {" "}
            <h1 onClick={navigateCrafts}>Crafts</h1>
          </div>
        </div>
        <div className={css.content} onClick={navigateMaps}>
          <div className={css.contentText}>
            {" "}
            <h1 onClick={navigateMaps}>Maps</h1>
          </div>
        </div>
        <div className={css.content} onClick={navigatePosters}>
          <div className={css.contentText}>
            {" "}
            <h1 onClick={navigatePosters}>Posters</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gift;
