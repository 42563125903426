import React from "react";

import css from "./LearningDevelopment.module.scss";
import img1 from "../../assets/img/learning/CL1.png";
import img2 from "../../assets/img/learning/Cl2.png";
import img3 from "../../assets/img/learning/CL3.png";
import img4 from "../../assets/img/learning/CL4.png";
import img5 from "../../assets/img/learning/CL5.png";
import img6 from "../../assets/img/learning/CL6.png";
import img7 from "../../assets/img/learning/CL7.png";
import img8 from "../../assets/img/learning/CL8.png";

import Nbar from "../nbar/Nbar";
import Avijit from "./Avijit/Avijit";

const LearningDevelopment = () => {
  return (
    <div className={css.wrapper}>
      <div className={css.navArea}>
        <div className={css.navContent}>
          <Nbar />
        </div>
      </div>
      <div className={css.textArea}>
        <h1>Learning & Development</h1>
        <h6>
          Triple Tree Tours provides training for History, Drama and Criminology
          students or graduates wanting to find ways and means into turning
          their degree subject into a vocation or living. The courses here are
          inroads into career objectives which are available online for those
          interested. They can be though, useful as a second income for city
          professional, a new mother or retired individual. At the moment, the
          courses as listed, are only available on Reed Online in English,
          however there are plans to extend the availability to multiple
          platforms and in various languages. Anyone interested in training with
          Triple Tree Tours, or have needs for course follow up should contact:
          training@tripletreetourslondon.com{" "}
        </h6>
      </div>

      <div className={css.contentArea}>
        <div className={css.mainContent}>
          <div className={css.content}>
            <div className={css.contentImage}>
              <img src={img1} alt="" />
            </div>
            <div className={css.contentText}>
              <h1>How to Make Money from Local Knowledge</h1>
              <h6>
                This course is designed to help those needing an extra income,
                or can be used by those wanting to make a career out of being a
                guide. What it does is isolate the method of using local
                knowledge to individual advantage. The course covers research
                methods, campaigns and marketing techniques and how to approach
                small businesses such as churches, pubs, hotels and clubs in
                your
              </h6>
            </div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
            className={css.content}
          >
            <div data-aos="fade-up" className={css.contentImage}>
              <img src={img2} alt="" />
            </div>
            <div data-aos="fade-up" className={css.contentText}>
              <h1>How to be a Film and/or Arts Reviewer</h1>
              <h6>
                This is a course designed for those with a media degree and the
                need to do something with it. The result can be working for a
                film PR company, running a website or blog…or networking in the
                arts world in your city, never having to pay for a ticket to an
                event ever again. It looks at the careers of those that have
                made this into a living and helps deep dive into this illusive
                and attractive world
              </h6>
            </div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
            className={css.content}
          >
            <div data-aos="fade-up" className={css.contentImage}>
              <img src={img3} alt="" />
            </div>
            <div data-aos="fade-up" className={css.contentText}>
              <h1>Study Skills, Memory & Recall</h1>
              <h6>
                Using techniques and tools proven to be useful for a vocation
                dependant on knowledge retention, this course is designed to
                deal with studying for an exam, or a test. Further to these
                areas are Project Management, Action Tracking and the overall
                maintenance of brain health. The course involves the use of
                tactics and methods, but not mnemonic devices.{" "}
              </h6>
            </div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
            className={css.content}
          >
            <div data-aos="fade-up" className={css.contentImage}>
              <img src={img4} alt="" />
            </div>
            <div data-aos="fade-up" className={css.contentText}>
              <h1>Time Management Masterclass</h1>
              <h6>
                This course is aimed at predominantly city dwellers - multi
                taskers and 9-5/5-9 workers. In reality, most workers in capital
                cities need more than one job to live comfortably: most of those
                running a small business work for a corporation whilst the
                business takes off. The course turns mountains into molehills,
                teaches priorities and deadline meeting and conflict
                avoidance/resolution
              </h6>
            </div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
            className={css.content}
          >
            <div data-aos="fade-up" className={css.contentImage}>
              <img src={img5} alt="" />
            </div>
            <div data-aos="fade-up" className={css.contentText}>
              <h1>Searching for (& Nailing) the Ideal Job</h1>
              <h6>
                The education world still sells the proposition that the degree
                subject as learned is an in road into a career of financial and
                personal satisfaction. Rarely, in reality is this the case:
                degrees prove a standard of education, but are not a guarantee
                into the world of well-paid work in the career area of choice.
                This course picks through the education, vocational world and
                the job search minefield
              </h6>
            </div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
            className={css.content}
          >
            <div data-aos="fade-up" className={css.contentImage}>
              <img src={img6} alt="" />
            </div>
            <div data-aos="fade-up" className={css.contentText}>
              <h1>Making a Cheap, Effective One Page Commercial Site</h1>
              <h6>
                There is now, in the modern world, the potential to make a money
                spinner out of a craft, a skill, idea or concept. The digital
                world facilitates the Download to Own of a picture, template,
                pattern, formula and method that can be read, shared and made
                into a side hustle. This course shows how to encapsulate the
                entity for potential monetisation and turn it into a One Page
                Commercial site
              </h6>
            </div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
            className={css.content}
          >
            <div data-aos="fade-up" className={css.contentImage}>
              <img src={img7} alt="" />
            </div>
            <div data-aos="fade-up" className={css.contentText}>
              <h1>Making an Ad for Tik Tok or You Tube</h1>
              <h6>
                Modern ads in the modern world have a very particular style and
                feel based mostly on the fast delivery of a one person, mobile
                phone video made with use of captions and emojis. This course
                teaches the student how to bring old school advertising values
                into modern methods used predominantly on Tik Tok and YouTube.
                It teaches the individual how to develop production values and
                brand awareness.
              </h6>
            </div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
            className={css.content}
          >
            <div data-aos="fade-up" className={css.contentImage}>
              <img src={img8} alt="" />
            </div>
            <div data-aos="fade-up" className={css.contentText}>
              <h1>
                Marketing, PR, Publicity & Social Media for the Hospitality
                Industry
              </h1>
              <h6>
                The hospitality industry was the most challenged by Covid,
                though before the Pandemic, pubs and clubs were already feeling
                the constraints brought on by internal and external forces:
                staffing/brand take over/property development/Town
                Planning/binge drinking - all have had their place in crippling
                this industry area. This course is for Business Development
                Managers and General Managers hoping to redress this imbalance.
                Covers brand management Top/Down & Bottom/Up
              </h6>
            </div>
          </div>
        </div>
      </div>

      <Avijit />
    </div>
  );
};

export default LearningDevelopment;
