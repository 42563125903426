import React from "react";
import css from "./AvijitDetails.module.scss";

const AvijitDetails = () => {
  const linkedInProfileUrl =
    "https://www.linkedin.com/in/avijit-arya-188a15238/";
  return (
    <div className={css.wrapper}>
      <div className={css.box}>
        <div className={css.content}>
          {" "}
          <span>Name : </span> <h1>Avijit Mondal</h1>
        </div>
        <div className={css.content}>
          {" "}
          <span>email : </span> <h1>d99cula@gmail.com</h1>{" "}
        </div>
        <div className={css.content}>
          {" "}
          <span>Phone + What's app : </span> <h1>0091 6290617034</h1>{" "}
        </div>
        <div className={css.content}>
          {" "}
          <span>LinkedIn : </span>{" "}
          <a
            href={linkedInProfileUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            LinkedIn Profile
          </a>{" "}
        </div>
      </div>
    </div>
  );
};

export default AvijitDetails;
