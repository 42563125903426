import React from "react";
import css from "./Ballads.module.scss";
import Nbar from "../../nbar/Nbar";
import img1 from "../../../assets/img/ballads/1.png";
import img2 from "../../../assets/img/ballads/2.png";
import img3 from "../../../assets/img/ballads/3.png";
import img4 from "../../../assets/img/ballads/4.png";
import img5 from "../../../assets/img/ballads/5.png";
import img6 from "../../../assets/img/ballads/6.png";
import img7 from "../../../assets/img/ballads/7.png";
const Ballads = () => {
  return (
    <div className={css.wrapper}>
      <div className={css.navArea}>
        <Nbar />
      </div>
      <div className={css.body}>
        <div className={css.content}>
          <div className={css.upperContent}>
            <div className={css.imageLeft}>
              <img src={img1} alt="" />
            </div>
            <div className={css.text}>
              <h1>Ballads & Murder Ballads</h1>
              <h6>
                It was customary at the time of execution, for an infamous
                criminal to have a ballad written for them by a member of the
                public with the ability to do so. In the run up to the
                execution, the ballad would be distributed as street literature
                and the ballad itself would have a folk status by the time of
                the execution. On the day itself, the music sheet, the poem, and
                pictures as posed here, would be sold on the streets during The
                Tyburn Fayre, and Tyburn Procession. Money was made via this
                means of exploitation
              </h6>
            </div>
            <div className={css.imageRight}>
              <img src={img2} alt="" />
            </div>
          </div>
          <div className={css.downContent}>
            <div className={css.downContentLeft}>
              <img src={img3} alt="" />
              <img src={img4} alt="" />
              <img src={img5} alt="" />
              <img src={img6} alt="" />
            </div>
            <div className={css.downContentRight}>
              <img src={img7} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ballads;
