import React, { useState, useRef } from "react";
import css from "./Crafts.module.scss";
import Nbar from "../../nbar/Nbar";
import Video from "../../../assets/video/CraftyPast.mp4";
const Crafts = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  function handlePlayPause() {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  }
  return (
    <div className={css.wrapper}>
      <div className={css.navArea}>
        <Nbar />
      </div>
      <div className={css.body}>
        <div className={css.video}>
          <video src={Video} controls ref={videoRef} />
          <button onClick={handlePlayPause} className={css.btn}>
            {isPlaying ? "Pause" : "Play"}
          </button>
        </div>
        <div className={css.text}>
          <h4>
            <span style={{ fontStyle: "italic", fontWeight: "bold" }}>
              Crafty Past
            </span>{" "}
            is an aspect of the tour business Triple Tree Tours. It is a YOUTUBE
            Channel with at its core collaboration - we invite a person with a
            template, pattern or skill that is downloadable to join the channel
            and the organisation as a small business or crafts person. To join
            there is no fee, just the need to be able to make a demonstration
            video and have a one page site. The videographer can be found - at
            inexpensive cost, purely on recommendation, and a link to a course
            on creating a one page commercial website can be provided. For more
            details or send enquiries <br />{" "}
            <span style={{ fontWeight: "bold" }}>
              {" "}
              to: crafts@tripletreetourslondon.com
            </span>
          </h4>
        </div>
      </div>
    </div>
  );
};

export default Crafts;
