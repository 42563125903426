import React, { useState } from "react";
import css from "./Payment.module.scss";
import NumberPicker from "react-widgets/NumberPicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PaymentFunction from "./PaymentFunction";
const Payment = () => {
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  return (
    <div className={css.wrapper}>
      <div className={css.numberOfPeople}>
        <h6>Number Of People</h6>
        <NumberPicker
          defaultValue={0}
          step={1}
          format={{ style: "currency", currency: "EUR" }}
        />
      </div>
      <div className={css.date}>
        <h6>Date</h6>
        <DatePicker
          selected={selectedDate}
          onChange={handleDateChange}
          dateFormat="dd/MM/yyyy"
        />
      </div>
      <div className={css.pay}>
        <PaymentFunction />
      </div>
    </div>
  );
};

export default Payment;
