const HistoricDataSix = [
  {
    id: 1,
    victim: "1716:James Radclyffe",
    crime: "Treason",
    fate: "Beheaded at The Tower",
  },
  {
    id: 2,
    victim: "1721:Walter Kennedy",
    crime: "Piracy",
    fate: "Hung at Executioners Dock",
  },
  {
    id: 3,
    victim: "1724: Jack Shepherd",
    crime: "Thief Taker",
    fate: "Hung at Tyburn",
  },
  {
    id: 4,
    victim: "1724: Joseph Blake",
    crime: "Highway Robbery",
    fate: "Hung at Tyburn",
  },
  {
    id: 5,
    victim: "1725: Jonathan Wild",
    crime: "Thief Taker/Pimp",
    fate: "Hung at Tyburn",
  },
  {
    id: 6,
    victim: "1726: The Burnworth Gang",
    crime: "Thieves/Gangsters",
    fate: " ",
  },
  {
    id: 7,
    victim: "Edward Burnworth/William Blewitt",
    crime: " ",
    fate: "Hung at Tyburn",
  },
  {
    id: 8,
    victim: "Emanuel Dickenson/Thomas Berry",
    crime: " ",
    fate: "Hung at Kennington Common",
  },
  {
    id: 9,
    victim: "John Leggee/John Higgs",
    crime: " ",
    fate: "Hung at Putney Common",
  },
  {
    id: 10,
    victim: "1726: Catherine Hayes",
    crime: "Treason/Murder",
    fate: "Burned at the Stake at Tyburn",
  },
  {
    id: 11,
    victim: "1737: Nicholas Williams",
    crime: "Piracy",
    fate: "Hung at Executioners Dock",
  },
  {
    id: 12,
    victim: "1741: Jenny Diver/Mary Young",
    crime: "Pickpocket",
    fate: "Hung at Tyburn",
  },
  {
    id: 13,
    victim: "1743: Thomas Rounce",
    crime: "Treason on the Seas",
    fate: "Hung at Executioners Dock",
  },
  {
    id: 14,
    victim: "1746: Arthur Elphinstone (Lord)",
    crime: "Treason",
    fate: "Beheaded at The Tower",
  },
  {
    id: 15,
    victim: "1746: William Boyd (Earl)",
    crime: "Treason",
    fate: "Beheaded at The Tower",
  },
  {
    id: 16,
    victim: "1746: Charles Radclyffe",
    crime: "Treason",
    fate: "Beheaded at The Tower",
  },
  {
    id: 17,
    victim: "1747: Simon Fraser",
    crime: "Treason",
    fate: "Beheaded at The Tower",
  },
  {
    id: 18,
    victim: "1750: James Macclaine",
    crime: "Highway Robbery",
    fate: "Hung at Tyburn",
  },
  {
    id: 19,
    victim: "1753: Archibald Cameron",
    crime: "Rebel",
    fate: "Hung & Beheaded at The Tower",
  },
  {
    id: 20,
    victim: "1759: Thomas Hide",
    crime: "Piracy",
    fate: "Hung at Executioners Dock",
  },
  {
    id: 21,
    victim: "1760: Laurence Shirley (Earl)",
    crime: "Murder/Wife beating",
    fate: "Hung at Tyburn",
  },
  {
    id: 22,
    victim: "1762: Thomas Smith",
    crime: "Piracy",
    fate: "Hung at Executioners Dock",
  },
  {
    id: 23,
    victim: "1767: Elizabeth Brownriggs",
    crime: "Murder/Cruelty",
    fate: "Hung at Tyburn",
  },
  {
    id: 24,
    victim: "1771: Samuel Roberts/",
    crime: " ",
    fate: " ",
  },
  {
    id: 25,
    victim: "Thomas Baccus",
    crime: "Coiners",
    fate: "Hurdled & Hung at Tyburn",
  },
  {
    id: 26,
    victim: "1772: John Shoales",
    crime: "Ship Stealing",
    fate: "Hung at Executioners Dock",
  },
  {
    id: 27,
    victim: "1774: Sixteen String Jack",
    crime: "Highway Robbery",
    fate: "Hung at Tyburn",
  },
  {
    id: 28,
    victim: "1777: William Dodd",
    crime: "Forger",
    fate: "Hung at Tyburn",
  },
  {
    id: 29,
    victim: "1779: James Hackman",
    crime: "Murder",
    fate: "Hung at Tyburn",
  },
  {
    id: 30,
    victim: "1783: John Austen",
    crime: "Highway Robbery",
    fate: "Hung at Tyburn",
  },
  {
    id: 31,
    victim: "1787: Henry Parsons",
    crime: "Mutiny",
    fate: "Hung at Executioners Dock",
  },
  {
    id: 32,
    victim: "1792: Spence Broughton",
    crime: "Highway Robbery",
    fate: "Hung then Gibbeted at Kennington Common",
  },
  {
    id: 33,
    victim: "1795: Jerry Abershawe",
    crime: "Highway Robbery",
    fate: "Hung at Kennington Common",
  },
];

export default HistoricDataSix;
