import React from "react";
import css from "./Lgbtqia.module.scss";
import ImmerNav from "../immersiveTours/ImmerNav";
import img1 from "../../../assets/img/Immersive/lgbtqia1.png";
import img2 from "../../../assets/img/Immersive/lgbtqia2.png";
import img3 from "../../../assets/img/Immersive/lgbtqia3.png";
import book from "../../../../src/assets/img/bk.png";
import { useNavigate } from "react-router-dom";

const Lgbtqia = () => {
  const navigate = useNavigate();
  const navigateOrderPage = () => {
    navigate("/checkout");
  };
  return (
    <div className={css.wrapper}>
      <div className={css.navArea}>
        <ImmerNav />
      </div>
      <div className={css.body}>
        <div className={css.upperBody}>
          <div className={css.upperBodyLeft}>
            <img src={img1} alt="" />
          </div>
          <div className={css.upperBodyRight}>
            <div>
              <h1>Triple Tree Tours </h1>{" "}
              <h2>LGBTQIA+ (from 45AD) Walking Tour</h2>
            </div>
            <div>
              <h6>
                <span style={{ fontWeight: "bold" }}>Description: </span>{" "}
                <span style={{ textDecoration: "underline" }}>Adult Only </span>{" "}
                tour of LGBTQIA covers the cultural, legal, political and
                liberal world of same sex relationships, cross-dressing and
                associated values. We delve into the importance of counter-
                culture, dandyism, and same sex attraction in royalty through
                the ages and in pre-Christion cultures. We go into punishments
                for homosexuality and lesbianism. The tour looks into the
                importance of LGBTQIA in the arts. We end the walk at an iconic
                gay London pub. The tour is 4 hours. It is highly unique,
                valuable resource and is much in demand. Meeting Place: London
                Eye Ticket Office
              </h6>
            </div>
          </div>
        </div>

        <div className={css.middleBody}>
          <div className={css.middleLeft}>
            <img src={img2} alt="" />
          </div>
          <div className={css.middleMiddle}>
            <div className={css.text1}>
              <h1>What is NOT Included</h1>
              <h6> Food & Drink</h6> <h6>Waterproofs</h6>{" "}
              <h6> Refunds or Tips</h6>
            </div>
            <div className={css.text2}>
              <h1>What IS INCLUDED</h1>
              <h6>Costumed Professional Guide</h6>
              <h6> Free Goods & Services from Triple Tree Tours </h6>
              <h6>Lifetime Access to Guide & After Sales Service </h6>
              <h6>Q&A for duration of tour</h6>
            </div>
          </div>
          <div className={css.middleRight}>
            <img src={img3} alt="" />
          </div>
        </div>

        <div className={css.bottomBody}>
          <div className={css.bottomBodyLeft}>
            <span>Dates for 2023</span>
            <h4 style={{ fontWeight: "bold" }}>May: 5, 7, 19</h4>
            <h4 style={{ fontWeight: "bold" }}>June: 2, 16, 23</h4>
            <h4 style={{ fontWeight: "bold" }}>July: 9, 16, 23, 30</h4>
            <h4 style={{ fontWeight: "bold" }}>August: 6, 13, 19</h4>
            <h4 style={{ fontWeight: "bold" }}>September: 8, 16, 30</h4>
            <h4 style={{ fontWeight: "bold" }}>October: 15, 22, 27</h4>
          </div>
          <div className={css.bottomBodyRight}>
            <h4 style={{ fontWeight: "bold" }}>
              BOOK NOW TO AVOID DISAPPOINTMENT
            </h4>
            <h4 style={{ fontWeight: "bold" }}>£110.00 ONLY</h4>
            <h4 style={{ fontWeight: "bold" }}>4 HOURS</h4>
            <h4>Friday Start: 5pm</h4>
            <h4>Sat/Sun Start 1pm</h4>
            <h4 style={{ fontWeight: "bold" }}>Date</h4>
            <h4 style={{ fontWeight: "bold" }}>Number of Participants</h4>
            <div className={css.checkoutArea}>
              <div className={css.checkout}>
                <div className={css.paymentBtn}>
                  <img style={{width:'10rem'}} src={book} alt="book now" onClick={navigateOrderPage} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lgbtqia;
