const HistoricDataSix = [
  {
    id: 1,
    victim: "1800: James Wilson",
    crime: "Treason on the Seas",
    fate: "Hung at Execution Dock",
  },
  {
    id: 2,
    victim: "1802: Governor Wall",
    crime: "Murder/Cruelty",
    fate: "Hung at Newgate",
  },
  {
    id: 3,
    victim: "1803: Edward Marcus Despard",
    crime: "Treason",
    fate: "Hung at Newgate",
  },
  {
    id: 4,
    victim: "1810: Cato Street Conspirators",
    crime: "Rebels",
    fate: "Hung then Beheaded at Newgate",
  },
  {
    id: 5,
    victim: "James Ings/William Davidson",
    crime: " ",
    fate: " ",
  },
  {
    id: 6,
    victim: "John Brunt",
    crime: " ",
    fate: " ",
  },
  {
    id: 7,
    victim: "1810: Cato Street Conspirators",
    crime: "Rebels",
    fate: "Transported for Life",
  },
  {
    id: 8,
    victim: "Charles Cooper/Richard Bradburn",
    crime: " ",
    fate: " ",
  },
  {
    id: 9,
    victim: "John Harrison/James Wilson/John",
    crime: "Strange",
    fate: " ",
  },
  {
    id: 10,
    victim: "1824: Henry Fauntleroy",
    crime: "Forgery",
    fate: "Hung at Newgate",
  },
  {
    id: 11,
    victim: "1830: William Watts",
    crime: "Piracy",
    fate: "Hung at Executioners Dock",
  },
  {
    id: 12,
    victim: "1837: James Greenacre",
    crime: "Murder",
    fate: "Hung at Newgate",
  },
  {
    id: 13,
    victim: "1840: Francis Courvoiser",
    crime: "Murder",
    fate: "Hung at Newgate",
  },
  {
    id: 14,
    victim: "1849: Marie Manning",
    crime: "Murder",
    fate: "Hung at Horsemonger Lane",
  },
  {
    id: 15,
    victim: "1864: Franz Muller",
    crime: "Robbery",
    fate: "Hung at Newgate",
  },
  {
    id: 16,
    victim: "1868: Michael Barratt",
    crime: "Rebel",
    fate: "Hung at Newgate",
  },
  {
    id: 17,
    victim: "1869: Frederick Hinsen",
    crime: "Murder",
    fate: "Hung at Newgate",
  },
  {
    id: 18,
    victim: "1870: Margaret Waters",
    crime: "Murder",
    fate: "Hung at Newgate",
  },
  {
    id: 19,
    victim: "1872: August Elliott",
    crime: "Murder",
    fate: "Hung at Newgate",
  },
  {
    id: 20,
    victim: "1885: Daniel Minahan",
    crime: "Murder",
    fate: "Hung at Newgate",
  },
  {
    id: 21,
    victim: "1896: Amelia Dyer",
    crime: "Child Farmer",
    fate: "Hung at Newgate",
  },
];

export default HistoricDataSix;
