import React from "react";
import css from "./Home.module.scss";
import homeVideo from "../../assets/video/home1.mp4";
import { useState, useRef } from "react";
import { FaMusic } from "react-icons/fa";
// import Nbar from "../nbar/Nbar";
import LogoSlide from "../logoslide/LogoSlide";
import { Button } from "react-responsive-button";
import Navbar from "../navbar/Navbar";
import Avijit from "../pages/Avijit/Avijit";
const Home = () => {
  const videoRef = useRef(null);

  const [isMuted, setIsMuted] = useState(false);
  const handleMuteClick = () => {
    videoRef.current.muted = !isMuted;
    setIsMuted(!isMuted);
  };
  return (
    <div className={css.wrapper}>
      <div className={css.navTop}>
        <Navbar />

        {/* <Nbar /> */}
      </div>

      <video ref={videoRef} autoPlay muted>
        <source src={homeVideo} />
      </video>

      <div onClick={handleMuteClick} className={css.soundbtn}>
        <Button onClick={handleMuteClick} className={css.sbtn}>
          <FaMusic className={css.icon} />

          {isMuted ? "" : ""}
          {/* <h1>MUSIC</h1> */}
        </Button>
      </div>

      {/* <div className={css.lowerbar}>
        <img src={woodImage} alt="" />
      </div> */}

      <div className={css.logoSlide}>
        <LogoSlide />
      </div>
      <div>
        <Avijit />
      </div>
    </div>
  );
};

export default Home;
