import React from "react";
import css from "./HistoricTimeline.module.scss";
import Nbar from "../../nbar/Nbar";
import HistoryData from "./HistoricTimelineData";
const HistoricTimeline = (props) => {
  return (
    <div className={css.wrapper}>
      <div className={css.header}>
        <Nbar />
      </div>

      <div className={css.body}>
        <div className={css.bodyTitle}>
          <h1>Triple tree tours criminal history of London timeline</h1>
        </div>
        <div className={css.bodySubTitle}>
          This is a resource to go with the other references available here that
          are going to be part of the narrative of the Tours available from
          Triple Tree Tours. It gives the reader a good sense of the development
          of The Criminal History of London. This is a general guide, with more
          focused emphasis on each of the particular tours as presented.
        </div>
        <div className={css.bodyTable}>
          <div className={css.tableContent}>
            <table className={css.table}>
              <tbody>
                {HistoryData.map((HistoryData) => (
                  <tr key={HistoryData.id}>
                    <td style={{ width: "17rem" }}>{HistoryData.title}</td>
                    <td>{HistoryData.descrip}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistoricTimeline;
