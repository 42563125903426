import React from "react";
import css from "./ExecutorsTorturers.module.scss";
import Nbar from "../../nbar/Nbar";
import ExecutorsData from "./ExecutorsTorturersData";
import ExecutorsData2 from "./ExecutorsData";
import ExecutorsData3 from "./ExecutorsData3";
import img1 from "../../../assets/img/executors/executors.png";

const ExecutorsTorturers = () => {
  return (
    <div className={css.wrapper}>
      <div className={css.header}>
        <Nbar />
      </div>

      <div className={css.body}>
        <div className={css.textArea}>
          <h1>London executioners</h1>
          <h5>
            Here is a list from medieval times to when the death penalty was
            abolished. Records are not available for Headsmen earlier than this,
            though cutting a head off in one blow hardly happened: it took
            usually 3 to 10 blows. An executioner is an agent of the state with
            a warrant to kill a criminal who has been sentenced to the death
            penalty. They have been either Headsmen (beheadings) or Hangmen
            (hangings){" "}
          </h5>
        </div>
        <div className={css.tableArea}>
          <div className={css.topTable}>
            <div className={css.tableLeft}>
              <table className={css.table}>
                <tbody>
                  {ExecutorsData.map((ExecutorsData) => (
                    <tr key={ExecutorsData.id}>
                      <td style={{ width: "15rem" }}>{ExecutorsData.title}</td>
                      <td style={{ width: "8rem" }}>{ExecutorsData.descrip}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className={css.tableRight}>
              {" "}
              <table className={css.table}>
                <tbody>
                  {ExecutorsData2.map((ExecutorsData2) => (
                    <tr key={ExecutorsData2.id}>
                      <td style={{ width: "15rem" }}>{ExecutorsData2.title}</td>
                      <td style={{ width: "8rem" }}>
                        {ExecutorsData2.descrip}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className={css.bottomTable}>
            <div className={css.tableLeft}>
              <table className={css.table}>
                <tbody>
                  {ExecutorsData3.map((ExecutorsData3) => (
                    <tr key={ExecutorsData3.id}>
                      <td style={{ width: "13rem" }}>{ExecutorsData3.title}</td>
                      <td>{ExecutorsData3.descrip}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className={css.tableRight}>
              <div className={css.image}>
                <img src={img1} alt="London executioners" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExecutorsTorturers;
