const HistoricDataThree = [
  {
    id: 1,
    victim: "1305: William Wallace",
    crime: "Rebel/Treason",
    fate: "Hung, Drawn & Quartered at Smithfield",
  },
  {
    id: 2,
    victim: "1306: Simon Fraser",
    crime: "Treason",
    fate: "Hung, Drawn & Quartered at The Tower",
  },
  {
    id: 3,
    victim: "1326: Hugh Despencer",
    crime: "Treason",
    fate: "Hung, Drawn & Quartered to The Tower",
  },
  {
    id: 4,
    victim: "1330: Roger Mortimer (Earl)",
    crime: "Heresy",
    fate: "Hung at Tyburn",
  },
  {
    id: 5,
    victim: "1347: John Graham (Earl)",
    crime: "Treason",
    fate: "Hung, Drawn & Quartered at The Tower",
  },
  {
    id: 6,
    victim: "1381: Simon Sudbury",
    crime: "Treason",
    fate: "Beheaded at The Tower",
  },
  {
    id: 7,
    victim: "1388: Robert Tresilian (Judge)",
    crime: "Heresy/Rebel",
    fate: "Hung/Garrotted at Tyburn",
  },
  {
    id: 8,
    victim: "1388: Simon De Burley",
    crime: "Treason",
    fate: "Beheaded at The Tower",
  },
  {
    id: 9,
    victim: "1397: Richard Fitzalan (Earl)",
    crime: "Treason",
    fate: "Beheaded at The Tower",
  },
  {
    id: 10,
    victim: "1399: John Hall",
    crime: "Murder/Treason",
    fate: "Hung, Drawn & Quartered at Tyburn",
  },
];

export default HistoricDataThree;
