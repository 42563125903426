const StuffOnSexData = [
  {
    id: 1,
    character: "Miss Lowes",
    role: "Prostitute",
    date: "-",
    areaoflondon: "Upper Charlotte Street",
    significance: "Black Courtesan",
  },
  {
    id: 2,
    character: "Miss Wilson",
    role: "Prostitute",
    date: "-",
    areaoflondon: "Litchfield Street",
    significance: "Black Courtesan",
  },
  {
    id: 3,
    character: "Black Harriot",
    role: "Prostitute & Bawd",
    date: "-",
    areaoflondon: "-",
    significance:
      "Was initially a slave from Jamaica, but worked her way into becoming a respected bawd with a high end client list, including the Earl of Sandwich. Died of TB in a debtors prison. The only black Bawd of note. ",
  },
  {
    id: 4,
    character: "Elizabeth Cresswell ",
    role: "Bawd",
    date: "1658",
    areaoflondon: "Moorgate/Cripplegate/Whetstone Park Lincoln's Inn",
    significance:
      "Was visited by Charles II Had a string of brothels Was very successful",
  },
  {
    id: 5,
    character: "Lord Baltimore",
    role: "Aristocratic Pimp",
    date: "1706 - 78",
    areaoflondon: "-",
    significance: "Had mix of races White x 5 Black x 1",
  },
  {
    id: 6,
    character: "Moll Hackabout",
    role: "Fictional Character in The Harlot's Progress based on real case of Kate Hackabout",
    date: "-",
    areaoflondon: "Arrives in the Bell Inn in Cheapside",
    significance: "Series of Plaques/plates by William Hogarth",
  },
  {
    id: 7,
    character: "Captain Edward Sellan",
    role: "Army Cadet who drove a mail coach between Cambridge and London. PornographerWrote for William Dugdale",
    date: "1818 - 66",
    areaoflondon: "1818 - 66",
    significance: "Serial seducer and shot himself in a room in Piccadilly",
  },
  {
    id: 8,
    character: "Emma Robinson",
    role: "Notorious Common Queane",
    date: "1608",
    areaoflondon: "-",
    significance:
      "Was infamous for solicitous behaviours: would sit outside beyond midnight",
  },
  {
    id: 9,
    character: "La Soeur De La Reine",
    role: "Pornography Story",
    date: "1800s",
    areaoflondon: "-",
    significance: "The Sister of the Queen",
  },
  {
    id: 10,
    character: "Sir John Gonson",
    role: "Justice of the Peace",
    date: "1700s",
    areaoflondon: "-",
    significance:
      "Was considered the Scourge of the Prostitutes. Was featured in the Harlots progress plates sentencing Kate Hackabout. Ruled over courts for over 50 years. Was a moralitarian, but founded the Foundling Hospital",
  },
  {
    id: 11,
    character: "Elizabeth Wisebourne",
    role: "Bawd and Procuress",
    date: "1600s",
    areaoflondon: "Drury Lane ",
    significance:
      "Ran the most expensive brothel in London. Specialised in restoring virginity and in curing venereal disease. Would recruit children from their sale outside St Martin's in the Fields",
  },
  {
    id: 12,
    character: "William Dugdale",
    role: "Pornographer",
    date: "1800s",
    areaoflondon: "Holywell Street",
    significance: "Was jailed 9 times and was consistently raided",
  },
  {
    id: 13,
    character: "Mary Wilson",
    role: "Bawd",
    date: "-",
    areaoflondon:
      "Multi PremisesOld Bond St/St. PancrasHall Place/St. John's Wood",
    significance: "Specialised in Flagellation",
  },
  {
    id: 14,
    character: "Edmund Curill",
    role: "Pornographer",
    date: "1725",
    areaoflondon: "Wrote about the behaviours of Lords",
    significance: "Was sentenced to the Pillory",
  },
  {
    id: 15,
    character: "ohn Camden Hotten",
    role: "Pornographer",
    date: "1800s",
    areaoflondon: "Holywell St",
    significance:
      "Extensive pornographer and distributor of La Soeur de la Reine",
  },
  {
    id: 16,
    character: "Pietro Aretino",
    role: "Poet & Pornographer",
    date: "Renaissance",
    areaoflondon: "Italy",
    significance: "European Influence",
  },
  {
    id: 17,
    character: "Fernando Henriques ",
    role: "Prostitute Historian",
    date: "-",
    areaoflondon: "-",
    significance: "-",
  },
  {
    id: 18,
    character: "Elizabeth Needham",
    role: "Bawd and Procuress",
    date: "1730",
    areaoflondon: "Park Place, St. James",
    significance:
      "Infamously cruel Bawd and Procuress with high end client list including the rapist Francis Charteris. Would get her girls from coaches coming into London, from homeless hostels in Holborn or from other brothels and would ",
  },
  {
    id: 19,
    character: "Margaret Fernseed ",
    role: "Bawd & Murderer",
    date: "1600",
    areaoflondon: "-",
    significance: "Was renowned for corrupting married women",
  },
  {
    id: 20,
    character: "John Garfield",
    role: "Writer",
    date: "1600 - 3",
    areaoflondon: "-",
    significance:
      "Wrote the serial 'The Wandering Whore' and other salacious pamphlets",
  },
  {
    id: 21,
    character: "Lucy Cooper",
    role: "Lucy Cooper",
    date: "-",
    areaoflondon: "-",
    significance: "Celebrated in folk songs",
  },
  {
    id: 22,
    character: "Priss Fotheringham",
    role: "Brothel Keeper & Entertainer",
    date: "1663",
    areaoflondon: "City",
    significance:
      "Inventor of The Chuck Game where Priss stands on her head and cully rumpers throw 16 half crowns into her commoditie. She died rich in 1668, after performing this stunt several times a day. She had a tavern at the corner of Whitecross and Old St and it was known as the Six Windmills - then The Chuck Office after her party piece",
  },
  {
    id: 23,
    character: "Nelly Fowler",
    role: "High Class Prostitute",
    date: "1880s",
    areaoflondon: "-",
    significance: "Gladstone tried to reform this prostitute of some reknown",
  },
  {
    id: 24,
    character: "Elizabeth Godfrey",
    role: "Prostitute & Murderer",
    date: "1807",
    areaoflondon: "-",
    significance: "Killed a lodger by stabbing them in the eye",
  },
  {
    id: 25,
    character: "Cora Pear",
    role: "Courtesan & Writer",
    date: "1870s",
    areaoflondon: "-",
    significance:
      "Had a string of aristocratic lovers including William of Orange with a reputation for almost a superhuman knowledge of love making. Died of cancer in 1886 and left her memoirs",
  },
  {
    id: 26,
    character: "Reverend Harold Francis Davidson",
    role: "Reformer",
    date: "1905",
    areaoflondon: "Westminster",
    significance: "Saved over 100 women",
  },
  {
    id: 27,
    character: "Jimmy Humphreys",
    role: "Club Owner & Pornographer",
    date: "1960s",
    areaoflondon: "Soho",
    significance:
      "Brought down the downfall of the Obscene Publications Squad. Would entertain corrupt officers at his home in Ibiza",
  },
  {
    id: 28,
    character: "Mrs Hannah Gould",
    role: "Bawd",
    date: "1742",
    areaoflondon: "Bow St Russell Street",
    significance: "Jewish Bagnio OwnerHighly pretentious and expensive",
  },
  {
    id: 29,
    character: "George Carpenter",
    role: "Business Owner",
    date: "1760s",
    areaoflondon: "Covent Garden",
    significance: "Moll Kings Coffee House owner after Tom King",
  },
  {
    id: 30,
    character: "Giacomo Casanova",
    role: "Writer",
    date: "1755",
    areaoflondon: "Italian Influence ",
    significance:
      "Wrote an account of London whores and Bawds. Was imprisoned for being a magician",
  },
  {
    id: 31,
    character: "Rose Marks",
    role: "Bawd",
    date: "-",
    areaoflondon: "Duke's Place, St. James",
    significance: "Jewish Brothel owner",
  },
  {
    id: 32,
    character: "Miss Fawkland",
    role: "Bawd",
    date: "1730s",
    areaoflondon: "St James St",
    significance:
      "Temples of Aurora/Flora and Mysteries were interconnected houses of pleasure offering different delights: Auroura/Children Flora/High Class Luxury Mysteries/Flagellation",
  },
  {
    id: 33,
    character: "Jack Harris",
    role: "Inn Keeper & Pimp",
    date: "1740s",
    areaoflondon: "Covent Garden The Shakespeare's Head",
    significance:
      "Author of John Harris List of Covent Garden Ladies. Best Seller list of 18th century whores and their attributes.8000 copies and reprints.2 booksellers were jailed for publishing the Whores List",
  },
  {
    id: 34,
    character: "Mother Cummnigs",
    role: "Bawd and Fence",
    date: "1816",
    areaoflondon: "-",
    significance: "Bawd who taught girls to steal",
  },
  {
    id: 35,
    character: "Walter",
    role: "Victorian Lecher",
    date: "-",
    areaoflondon: "-",
    significance:
      "Infamous Victorian lecher who had encounter with hundreds of prostitutes and led a very dangerous life",
  },
  {
    id: 36,
    character:
      "Elizabeth Holland AKA Donna Hollander Beta Breston Eliza Caunce Longa Maria Maria Petit (Whores) ",
    role: "Bawd & Hotelier",
    date: "1630s",
    areaoflondon: "Southbank & Paris Gardens",
    significance:
      "Well renown Bawd and Boarding House keeper who had a long life as a madam. The Holland's Leaguer stood on the Southbank on its own island and lasted years providing luxurious accommodation and women. Had its own staff, medics, cooks. Lasted well for the time. Both Cromwell and Charles I tried to ban it and Daniel Lupton wrote a damning pamphlet about it. Was nicknamed The Nobs Island. James I and George Villiers were regulars. Hopton Street is the spot where it was",
  },
  {
    id: 37,
    character: "Damaris Page",
    role: "Bawd",
    date: "1669",
    areaoflondon: "Wapping",
    significance:
      "Bawd who specialised in providing seamen with prostitutes. Was responsible for the death of a prostitute after carrying out an abortion with an oyster fork",
  },
  {
    id: 38,
    character: "Fanny Murray",
    role: "Celebrated Prostitute",
    date: "1729-1778",
    areaoflondon: "Covent Garden",
    significance:
      "One of the first celebrity whores. Was given a snuff box worth £40 by one of her lovers. Was the Toast of the Town in 1740. She survived the pox and got an expensive treatment. She died wealthy and loved in 1778",
  },
  {
    id: 39,
    character: "Finishes",
    role: "Boozing Dens",
    date: "1800s",
    areaoflondon: "-",
    significance:
      "Slang for boozing dens in Victorian London where prostitution was rife. ",
  },
  {
    id: 40,
    character: "Bawdy Baskets",
    role: "Whores/Peddlars",
    date: "-",
    areaoflondon: "-",
    significance:
      "Name given to prostitutes that would go door to door selling household merchandise looking for opportunities",
  },
  {
    id: 41,
    character: "Ann George Bellamy",
    role: "Prostitute",
    date: "1700s",
    areaoflondon: "-",
    significance:
      "Infamous Georgian prostitute who was in and out of debtors prison",
  },
  {
    id: 42,
    character: "Maurice of London",
    role: "Bishop",
    date: "1036",
    areaoflondon: "St. Pauls",
    significance:
      "Was the bishop of William the Conqueror. Had vociferous sexual appetite. Rebuilt St. Pauls",
  },
  {
    id: 43,
    character: "Jenny Diver",
    role: "Prostitute/Thief",
    date: "1700",
    areaoflondon: "Covent Garden",
    significance: "The heroine of the Beggars Opera",
  },
  {
    id: 44,
    character: "Mother Jane Douglas",
    role: "Bawd",
    date: "1690s",
    areaoflondon: "-",
    significance:
      "Was a focus for 'The Nocturnal Revels. Fleeced princes and peers",
  },
  {
    id: 45,
    character: "Mother Whyburn",
    role: "Bawd and Procuress",
    date: "1700s",
    areaoflondon: "Covent Garden",
    significance:
      "Was a high class bawd who had worked in a Seraglio from the age of 14. Whyburn selected girls when they were little more than children and coached them in the social graces in order to secure them into the gentry. She made the claim that all her girls were either seamstresses or parson's daughters from the country. Died in 1719. Sally Salisbury was under her tutorage",
  },
  {
    id: 46,
    character: "William Gladstone",
    role: "Prime Minister",
    date: "1850s",
    areaoflondon: "Westminster ",
    significance:
      "Tried to reform prostitutes, was infatuated with a beautiful prostitute called Elizabeth Collins",
  },
  {
    id: 47,
    character: "Society for the Reformation of Manners",
    role: "Moral Society",
    date: "1699 - 1750s",
    areaoflondon: "-",
    significance:
      "A variety of moral based bodies that attacked prostitution. Had the backing of staunch protestants William & Mary. In 1699 500 disorderly houses were supressed by Samuel Cooke and arrested hundreds of prostitutes in the 1720s",
  },
  {
    id: 48,
    character: "Daniel Defoe",
    role: "Author",
    date: "1703",
    areaoflondon: "St James",
    significance: "Wrote Moll Flanders and was pilloried at Cheapside",
  },
  {
    id: 49,
    character: "Yellow Haired Kitty",
    role: "Prostitute",
    date: "1800s",
    areaoflondon: "-",
    significance: "Prostitute who 'Walter had an affair with",
  },
  {
    id: 50,
    character: "Patience Russell ",
    role: "Courtesan",
    date: "1650s",
    areaoflondon: "Covent Garden",
    significance:
      "Great beauty who is named in The Wandering Whore. Lord Rochester left her 160.00.Was probably one of the whores of Moll Cresswell",
  },
  {
    id: 51,
    character: "Sally Salisbury ",
    role: "Courtesan",
    date: "Courtesan",
    areaoflondon: "St James",
    significance:
      "Was infamous beauty and the first working class courtesan. Was the daughter of a butcher. Was cured of pox by Moll Wisebourne. Was consistently re-virginised. Attacked one of her lovers with a knife but was forgiven for it. Was mistress to Francis Charteris. Had Dukes of Richmond and St Albans as clients",
  },
  {
    id: 52,
    character: "Charles Carrington",
    role: "Pornographer",
    date: "1901",
    areaoflondon: "Holywell St",
    significance: "Victorian Pornography",
  },
  {
    id: 53,
    character: "Colonel Francis Charteris",
    role: "Cheat/Rapist/Rake",
    date: "1700s",
    areaoflondon: "St. James",
    significance:
      "Was the man whom received most of the procured virgins of the age. He was on the receiving end of a complaint and was pardoned, though sentenced to death. Had a procuring valet called Jack Gourlay. He won the title of Colonel playing cards. He made a lot of money during The South Sea Bubble. Was the first member of The Hellfire Club. Ended his life in Edinburgh after his name was ruined in London",
  },
  {
    id: 54,
    character: "Ann Bond ",
    role: "Victim of Francis Charteris",
    date: "1700s",
    areaoflondon: "-",
    significance:
      "Was a tall peasant girl that Chateris took a shine to. She was given a false name as to her keeper and when she found out and tried to leave was assaulted by his servants and thrown out",
  },
  {
    id: 55,
    character: "Jane Shore ",
    role: "Reprised Courtesan",
    date: "-",
    areaoflondon: "Ludgate",
    significance:
      "Was born Elizabeth Jane Lambert and married a goldsmith called Matthew Shore. The marriage was unsuccessful- she became the mistress of Edward IV, though after Edward's death Richard III called her a harlot and made her walk through the streets naked. She was incarcerated in Ludgate but was proposed to by the King's solicitor Thomas Lyneham.",
  },
  {
    id: 56,
    character: "Queenie Gerald",
    role: "Madame",
    date: "1910s",
    areaoflondon: "Haymarket",
    significance: "Infamous brothel keeper who is raided in 1913",
  },
  {
    id: 57,
    character: "Constanzia Jones",
    role: "Prostitute",
    date: "1725",
    areaoflondon: "Covent Garden",
    significance:
      "Prostitute executed for stealing from her client. Was famous for frequenting Newgate (over 20 times)",
  },
  {
    id: 58,
    character:
      "Petronella, Ionette, Alice Strumpette, Clarice la Clatter Ballock, Rosamund Sugarcunt, One Eyed Pey (Bald Cunted Poll)",
    role: "Names of Prostitutes",
    date: "-",
    areaoflondon: "-",
    significance: "-",
  },
  {
    id: 59,
    character: "Baron Audley",
    role: "Aristocrat",
    date: "1573 - 1631",
    areaoflondon: "-",
    significance: "Sexual Deviant",
  },
  {
    id: 60,
    character: "Elizabeth Lyon",
    role: "Prostitute",
    date: "1700s",
    areaoflondon: "Newgate",
    significance: "Helped thief taker Jack Shepherd escape",
  },
  {
    id: 61,
    character: "Wetherby's",
    role: "Coffee House",
    date: "1747",
    areaoflondon: "Russell St.",
    significance:
      "After the death of Moll King, Wetherby's rises as the Coffee house of choice for low lives and prostitutes",
  },
  {
    id: 62,
    character: "Kitty Fisher",
    role: "Society Wit",
    date: "1700s",
    areaoflondon: "St James",
    significance:
      "Captivating beauty and wit and society wit. Posed for Sir Joshua Reynolds as Cleopatra. She had the Duke of York as a client and was disgruntled after being left a 50.00 tip.",
  },
  {
    id: 63,
    character: "Lavinia Fenton",
    role: "Actress & Society Coutesan",
    date: "1708 - 60",
    areaoflondon: "Covent Garden",
    significance:
      "Born out of wedlock and raised in a pub. Was in The Beggars Opera as Polly Peachum. Was the mistress of nobility and the Duke of Bolton and married him, outlived him and died wealthy",
  },
  {
    id: 64,
    character: "De Quincey Thomas",
    role: "Wit, Rake and Writer",
    date: "1840s",
    areaoflondon: "-",
    significance: "Author of sensational material",
  },
  {
    id: 65,
    character: "David Belasco",
    role: "Pimp & Brothel Owner",
    date: "1850s",
    areaoflondon: "-",
    significance: "-",
  },
  {
    id: 66,
    character: "Charlotte Hayes",
    role: "Brothel Keeper & Religious Reformer",
    date: "1700s",
    areaoflondon: "Gt. Marlborough St",
    significance: "Top end brothel keeper who started a protestant nunnery",
  },
  {
    id: 67,
    character: "Elizabeth Adkins aka Moll King",
    role: "Coffee house Keeper",
    date: "1750s",
    areaoflondon: "Covent Garden",
    significance:
      "Character who inspired Moll Flanders. Very successful business owner of the 18th century. Died in 1747 and was taken over and renamed Wetherbys. It ran along one side of the piazza",
  },
  {
    id: 68,
    character: "Cynthia Payne",
    role: "Cynthia Payne",
    date: "1970s",
    areaoflondon: "1970s",
    significance: "Brothel Keeper and BDSM Specialist",
  },
  {
    id: 69,
    character: "Sally Lodge, Elizabeth Needham, Kate Hackabout",
    role: "Pilloried at the same time and place as prostitutes and keepers of disorderly houses",
    date: "30/4/1730",
    areaoflondon: "New Palace Yard Westminster Palace",
    significance:
      "Example of punishment for prostitutes. Elizabeth Needham died after this stint in the pillory before she was due another. The pillory or whipping was the common punishment",
  },
  {
    id: 70,
    character: "Nocturnal Revels",
    role: "Books",
    date: "1779",
    areaoflondon: "-",
    significance: "2 Volume Books on Prostitutes",
  },
  {
    id: 71,
    character: "Reginald Baccus",
    role: "Pornographer",
    date: "1858 - 1921",
    areaoflondon: "Holywell St",
    significance: "Published Pornographer",
  },
  {
    id: 72,
    character: "Procuring",
    role: "The act of taking a young girl and introducing her into the life of sex and vice",
    date: "16th, 17th and 18th century practice",
    areaoflondon: "Covent Garden",
    significance:
      "First example was in 1438 where a woman called Margaret procured a young woman called Isobel Lane for a gentleman",
  },
  {
    id: 73,
    character: "Fanny Hill aka Memoirs of a Woman of Pleasure",
    role: "Salacious account of a loose woman and bestseller",
    date: "-",
    areaoflondon: "-",
    significance:
      "Starts as a Saphic novel, then Fanny is sold to a dirty old man but survives to wed a handsome man of choice. The writer, John Cleland paid for a release out of debtors prison with the royalties",
  },
  {
    id: 74,
    character: "Sir Francis Dashwood",
    role: "Hellfire Club Founder",
    date: "1721",
    areaoflondon: "Piccadilly",
    significance:
      "Infamous wicked man of the 18th century who ran a club whose practices were bizarre rituals",
  },
  {
    id: 75,
    character: "Ron Davey",
    role: "Pornographer",
    date: "1950s",
    areaoflondon: "Soho",
    significance: "Ron the Dustman - Soho distributor of nudist photos",
  },
  {
    id: 76,
    character: "Marthe Watts",
    role: "Gang Madam",
    date: "1913",
    areaoflondon: "European",
    significance:
      "Mesina brothers controlled prostitute who operated all over Europe until persuading a drunken Englishman to marry her",
  },
  {
    id: 77,
    character: "Lady Barbara Villiers Castlemaine",
    role: "Courtesan",
    date: "1660s",
    areaoflondon: "Westminster",
    significance:
      "Lover of Charles II. Had many lovers and children. Was in receipt of The Whores Petition. Was eventually banished",
  },
  {
    id: 78,
    character: "Teresa Constanzia Philips",
    role: "Teresa Constanzia Philips",
    date: "1750s",
    areaoflondon: "St James/Soho 28 Charlotte St",
    significance:
      "Flagellant and inventor of The Berekley Horse. Was seduced at the early age of 12 by The Earl of Chesterfield. She published her memoirs including all of her lovers and their habits",
  },
  {
    id: 79,
    character: "Beta Brestonia, Eliza Caune, Longa Maria, Maria Petit",
    role: "Paris Gardens Whores",
    date: "1740s",
    areaoflondon: "Southbank",
    significance:
      "Paris Gardens wa a place on an inlet on the Southbank infamous for Stews, then brothels: close to the Holland League",
  },
  {
    id: 80,
    character: "Madame Cornely",
    role: "Madam and Brothel Keeper",
    date: "1763",
    areaoflondon: "Soho Square",
    significance:
      "Brothel that had a disorderly house accusation levelled in 1771",
  },
  {
    id: 81,
    character: "The Weather in the Streets",
    role: "Watershed book on the risks of promiscuity and abortion",
    date: "1936",
    areaoflondon: "-",
    significance:
      "Rosalind Lehmann author of well-respected book considered years ahead f its time",
  },
  {
    id: 82,
    character: "Anne Turner",
    role: "Bawd & Dressmaker",
    date: "1575 - 1615",
    areaoflondon: "Hammersmith paternoster Row",
    significance: "Both brothel owner and dressmaker for James I",
  },
  {
    id: 83,
    character: "Mary Cut & Come Again",
    role: "Cutpurse and Songwriter",
    date: "1745",
    areaoflondon: "-",
    significance:
      "Mary White. Favourite trick was to spurt milk from her breasts at Watchmen. Behaved badly and with violence at her trials",
  },
  {
    id: 84,
    character: "Dress Lodgers",
    role: "Girls who were loaned clothes in lieu of payment",
    date: "-",
    areaoflondon: "-",
    significance:
      "Often the trap of young woman at the mercies of a procuress. The girls had to work as prostitutes to pay for the clothes or be thrown 8in debtors prison until they were paid for",
  },
  {
    id: 85,
    character: "Whores Fates",
    role: "Marriage, Keeper, Death, Disease, The Rise to Madam/Bawd, Vizard/Theatre Whore, Courtesan/Nobility, Whore, Whipping, Bridewell, Gropecunt Lane, Bedlam",
    date: "-",
    areaoflondon: "-",
    significance:
      "From the Roman period onwards until the Victorian era, the risk of syphilis was great and the penalties for prostitution harsh in spite of the widespread use of them throughout the centuries ",
  },
  {
    id: 86,
    character: "Elizabeth Barry",
    role: "Actress/Prostitute",
    date: "1658 - 1713",
    areaoflondon: "Covent Garden",
    significance:
      "Prostitute turned into great actress by Lord Rochester. Was eventually bit by a rabid dog",
  },
  {
    id: 87,
    character: "Daniel Lupton",
    role: "Pamphleteer",
    date: "1630",
    areaoflondon: "Southbank",
    significance:
      "Created a Broadsheet on Paris Garden. A Foule Denne Faire Garden",
  },
  {
    id: 88,
    character: "Kate Hamilton",
    role: "Business Owner",
    date: "1850s",
    areaoflondon: "Piccadilly",
    significance:
      "Ran Café Royal in Princess St. She was also known by her other name of Mayhews and ran high class night houses",
  },
  {
    id: 89,
    character: "Horatio Bottomley",
    role: "Publisher",
    date: "1879 - 1912",
    areaoflondon: "Piccadilly",
    significance:
      "Founder, Editor and campaigner of John Bull. Founded a club in Bond St",
  },
  {
    id: 90,
    character: "Annie Chapman",
    role: "Victim/Prostitute",
    date: "1888",
    areaoflondon: "Spitalfields",
    significance:
      "One of the Jack the Ripper victims who was killed in 29 Hanbury St",
  },
  {
    id: 91,
    character: "Sarah Dubery",
    role: "Bawd",
    date: "1800",
    areaoflondon: "Covent Garden",
    significance:
      "High Class Bawd who ran a brothel in the 1800s. She was the regular mistress of a Swedish ambassador. ",
  },
  {
    id: 92,
    character: "Nell Gwynn",
    role: "Actress/Orange, Girl/Mistress",
    date: "1670s",
    areaoflondon: "Covent Garden Piccadilly ",
    significance:
      "Her mother kept a Bawdy house and here is where she was born. Her early life was as a Covent Garden Orange Girl. She was then an actress: her plays included The City Lady/Folly Reclaimed/An Evening's Love/The Mock Astrologer/The Husband His Own Cukold/The Maiden Queen. Became the lover of Charles II, but she referred to him as Charles III due to her having two previous lovers named Charles. Had one son by the king and he became the Duke of St. Albans. A great wit and favourite and was left 60k by Charles on his death. She was paid 1500 a year for the rest of her life and by the time she was 50 was worth 100k",
  },
  {
    id: 93,
    character: "Mary Jeffries",
    role: "Brothel Owner",
    date: "1840s",
    areaoflondon: "Chelsea Hampstead",
    significance:
      "Owned 8 brothels and had a reputation as being very wicked. She was the ongoing enemy of the journalist W T Stead",
  },
  {
    id: 94,
    character: "Janice Jones",
    role: "Madame",
    date: "1940s",
    areaoflondon: "Soho",
    significance:
      "A madam jailed for keeping brothels, and for controlling the prostitutes that lived and worked in them. In 1966 was charged with blackmail",
  },
  {
    id: 95,
    character: "Jane Goadby",
    role: "Prostitute",
    date: "1770s",
    areaoflondon: "Soho",
    significance:
      "An innovative prostitute who brought the French style to the Soho brothels in 1750",
  },
  {
    id: 96,
    character: "Betsy Careless",
    role: "Bawd",
    date: "1739",
    areaoflondon: "Covent Garden",
    significance: "-",
  },
  {
    id: 97,
    character: "Samuel Pepys",
    role: "Diarist",
    date: "1700s",
    areaoflondon: "Piccadilly",
    significance:
      "Famous journalist and commentator, but also individual with perversions and peccadilloes. Had a difficult relationship with wife due to her having a vaginal abscess 3 inches deep. He found it difficult to believe that women could enjoy sex. A molester of his servant Mary Mercer. Was an avid fan of brothels and was obsessed with defecating. He once went into an alehouse and paid for a beer so he could defecate in the fireplace. He once came to orgasm during a mass in Westminster by thought alone. He was highly critical of the court of Charles II",
  },
  {
    id: 98,
    character: "Earl of Rochestor (John Wilmot)",
    role: "Court favourite of Charles II and poet/satirist",
    date: "1640s - 1670s",
    areaoflondon: "St. James",
    significance:
      "Tall dark and handsome satirist of the period. Highly articulate of the more sleazy elements of life around at the time. Was a Royalist spy during the Restoration. He was a renowned bisexual and had his work censored by the government for its homosexual content. 'A Ramble in St' James's Park' is a poem that displays London in all its sleazy glory. In it the main heroine 'Corrina' appears with her lewd cunt that shall soon be drenched by all the seed in town",
  },
  {
    id: 99,
    character: "John Garfield",
    role: "Pamphleteer",
    date: "1661",
    areaoflondon: "Soho",
    significance:
      "Published The Wandering Whore, a pamphlet about the meanderings of a prostitute - commonly thought to be based on Priss Fotheringham, the bawd and sexual entertainer",
  },
  {
    id: 100,
    character: "Frederick Hankey ",
    role: "Pornography Smuggler",
    date: "1860s",
    areaoflondon: "City",
    significance:
      "Had a brother in the Bank of England and used couriers in the foreign Office to distribute pornography",
  },
  {
    id: 101,
    character: "Sir Charles Sedley MP",
    role: "MP and Agent ,Provocateur, Rake and Libertine",
    date: "1660s - 1701",
    areaoflondon: "City",
    significance:
      "An incident in Oxford Kates Tavern noted him enjoying a crowd looking at him naked and dipping his cock into a goblet of wine. He drank the King's health",
  },
  {
    id: 102,
    character: "Colonel James Luttrell",
    role: "Aristocrat and pervert",
    date: "1774",
    areaoflondon: "St James",
    significance:
      "Renowned debaucher of young girls was violent towards women. Went to a notorious party dressed as a corpse",
  },
  {
    id: 103,
    character: "Lord Fumble",
    role: "Immoral Peer",
    date: "1773",
    areaoflondon: "-",
    significance: "Aka The Earl of Harrington",
  },
  {
    id: 104,
    character: "Tom Browne",
    role: "Satirist",
    date: "1650s",
    areaoflondon: "Piccadilly",
    significance:
      "Satirist of the whores of Drury Lane. Wrote on the nature of Actress/Whore. The theatre was where Libertines would seek a pretty women and this was accepted",
  },
  {
    id: 105,
    character: "Link Boys",
    role: "Torch Bearers Rent Boys",
    date: "Pre Gas Light",
    areaoflondon: "-",
    significance:
      "Were responsible for leading the wealthy with light around dark areas before street lighting. Would subsidise income by selling sexual favours. Early rent boys",
  },
  {
    id: 106,
    character:
      "Aphrodisiacs, Asparagus, Coriander Seeds soaked in White Wine, Saffron Boiled in Red Wine, Lettuce",
    role: "-",
    date: "15/16/17/18th Centuries",
    areaoflondon: "-",
    significance: "-",
  },
  {
    id: 107,
    character: "Dr. Pedro Pintor",
    role: "VD Doctor",
    date: "1493",
    areaoflondon: "Italy",
    significance:
      "Recognised and diagnosed Gonnoreahh or The Burning. Spread across Europe over 2 years. Borgias were infected. Caused issues in the military",
  },
  {
    id: 108,
    character: "Condoms/Cundums",
    role: "Sexual Aid",
    date: "1667",
    areaoflondon: "-",
    significance:
      "See The Earl of Rochester and The Panegyrick Upon Cundums Hugely popular and were manufactured out of sheep's intestines. The Process: soak intestines in water for a number of hours/soak again in a weak alkaline solution after turning them inside out/change the solution every 12 hours/remove the guts, then wash in soap and water/Were reused by prostitutes ",
  },
  {
    id: 109,
    character: "Colonel Condom De Morbo Gallico",
    role: "Dr of Venereal Disease",
    date: "1564",
    areaoflondon: "Italy",
    significance:
      "Invented a linen sheath that when dipped in a solution of salt and herbs would be a protection against disease",
  },
  {
    id: 110,
    character: "Ellen De Evesham",
    role: "Bawd",
    date: "1339",
    areaoflondon: "City",
    significance:
      "Kept a disorderly house on Fleet St and the prostitutes would attack passers by",
  },
  {
    id: 111,
    character: "Vere Street Coterie",
    role: "Male Brothel",
    date: "1814",
    areaoflondon: "West End",
    significance: "-",
  },
];
export default StuffOnSexData;
