import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Nbar.css";
const Nbar = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  return (
    <nav>
      <div className="wrap">
        <div className={click ? "nbar-menu active" : "nbar-menu"}>
          <ul>
            {" "}
            <li className="nbarList">
              <Link className="nbarLink" to="/">
                Back to <br /> Homepage
              </Link>
            </li>
            <li className="nbarList">
              <Link className="nbarLink" to="/learning&development">
                Learning & <br /> Development
              </Link>
            </li>
            <li className="nbarList">
              <Link className="nbarLink" to="/campaigns">
                Campaigns
              </Link>
            </li>
            <li className="nbarList">
              <Link className="nbarLink" to="/resources">
                Resources
              </Link>
            </li>
            <li className="nbarList">
              <Link className="nbarLink" to="/Ts-&-Cs">
                Ts & Cs
              </Link>
            </li>
          </ul>
        </div>

        {/* <div className={css.hamburgerMenu} onClick={handleShowNavbar}>
        <button className={css.hamburger} onClick={handleShowNavbar}>
          <GiHamburgerMenu />
        </button>
        
      </div> */}

        <div className="nbarmenu-icon" onClick={handleClick}>
          <i
            className={click ? "fas fa-times" : "fas fa-bars"}
            style={{ fonstSize: "2rem" }}
          />
        </div>
      </div>
    </nav>
  );
};

export default Nbar;
