import React from "react";
import css from './ImmersiveTourThree.module.scss'
import img1 from "../../../../assets/img/tour/tour3.png";
import logo from '../../../../assets/logo/logof.png'
// import TourRoutesNav from "../TourRoutesNav";
import { useNavigate } from "react-router-dom";
import book from '../../../../assets/img/bk.png'

const ImmersiveTourThree = () => {
  const navigate = useNavigate();  
  const navigateOrderPage = () => {
    navigate("/checkout");
  };
  return (
    <div className={css.wrapper}>
      {/* <div className={css.navBar}>
        <TourRoutesNav />
      </div> */}
      <div className={css.container}>
        <div className={css.leeftSide}>
          <div className={css.logo}>
            <img src={logo} alt="" />
          </div>
          <div className={css.text}>
            <span>
              £90 <br />
              <span style={{ fontWeight: '700' }}>BOOK</span> <br />
              Tyburn:<br />
              Execution,<br />
              Mutilation &<br />
              Punishment in<br />
              London<br />
              4 Hours<br />
            </span>
          </div>
          <div className={css.paymentBtn}>
              <img style={{width:'7rem'}} src={book} alt="book now" onClick={navigateOrderPage} />
            </div>
        </div>
        <div className={css.rightSide}>
          <div className={css.image}>
            <img src={img1} alt="" />
          </div>
          <div className={css.text}>
            <span>
              This tour is very unique: it covers 700 years of cultural and penal history. The Bloody
              Code was an era where there were close to 300 crimes the public could be hanged for.
              This tour covers Newgate, Tyburn, Executions, Mutilations and Punishments.
              It also looks deep at the culture that pervaded bodily punishment as spectator sport, at
              the frequency and range of morbid mutilation.
              We look into where this happened, why and for how long. We look at the range of
              punishments and the reasoning behind them.
              The route is around six miles covering the exact route of The Tyburn Procession -
              where we pick up a convict and walk through the distance from prison to hanging tree.
              We go through the history of this aspect of London life whilst discussing a typical
              Tyburn Fayre. We have a half way refreshment at St. Giles.

            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImmersiveTourThree;
