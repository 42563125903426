const HistoricDataFour = [
  {
    id: 1,
    victim: "1400: Roger Clarendon",
    crime: "Treason",
    fate: "Hung, then Beheaded at Tyburn",
  },
  {
    id: 2,
    victim: "1410: John Badby",
    crime: "Heresy",
    fate: "Burned at the Stake at Smithfield",
  },
  {
    id: 3,
    victim: "1413: John Oldcastle",
    crime: "Heresy",
    fate: "Burned at the Stake at Smithfield",
  },
  {
    id: 4,
    victim: "1427: John Nave",
    crime: "Robbery",
    fate: "Hanged at Tyburn",
  },
  {
    id: 5,
    victim: "1446: John David",
    crime: "Apprentice",
    fate: "Hanged at Tyburn",
  },
  {
    id: 6,
    victim: "1449: John Tiploft",
    crime: "Treason",
    fate: "Beheaded at The Tower",
  },
  {
    id: 7,
    victim: "1462: John De Vere (Earl)",
    crime: "Treason",
    fate: "Beheaded at The Tower",
  },
  {
    id: 8,
    victim: "1483: William Hastings (Baron)",
    crime: "Treason",
    fate: "Beheaded at The Tower",
  },
  {
    id: 9,
    victim: "1486: Humphrey Stafford",
    crime: "Treason",
    fate: "Beheaded at The Tower",
  },
  {
    id: 10,
    victim: "1495: William Stanley",
    crime: "Treason",
    fate: "Beheaded at The Tower",
  },
  {
    id: 11,
    victim: "1497: James Tuchet (Baron)",
    crime: "Treason",
    fate: "Beheaded at The Tower",
  },
  {
    id: 12,
    victim: "1497: Nicholas Carew",
    crime: "Treason",
    fate: "Beheaded at The Tower",
  },
  {
    id: 13,
    victim: "1497: Thomas Flamank",
    crime: "Rebel",
    fate: "Hung, then Beheaded at Tyburn",
  },
  {
    id: 14,
    victim: "1497: Michael Joseph",
    crime: "Rebel",
    fate: "Hung at Tyburn",
  },
  {
    id: 12,
    victim: "1499: Edward Plantagenet",
    crime: "Pretender",
    fate: "Beheaded at The Tower",
  },
  {
    id: 12,
    victim: "1499: Perkin Warbreck",
    crime: "Pretender",
    fate: "Hung at Tyburn",
  },
];

export default HistoricDataFour;
