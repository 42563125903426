import React from "react";
import css from "./Map3.module.scss";
import img1 from "../../../../assets/img/maps/map3img1.png";
import img2 from "../../../../assets/img/maps/map3img2.png";
import img3 from "../../../../assets/img/maps/map3img3.png";
const Map3 = () => {
  return (
    <div className={css.wrapper}>
      <div className={css.mainContent}>
        <div className={css.images}>
          <div className={css.image}>
            <img src={img1} alt="" />
            <h5>London Aerial Roman: 45 - 457AD</h5>
          </div>
          <div className={css.image}>
            <img src={img2} alt="" />
            <h5>London Aerial Roman & Britannic 45 - 457AD (+)</h5>
          </div>
          <div className={css.image}>
            <img src={img3} alt="" />
            <h5>Southbank Stews: 1192 - 1553</h5>
          </div>
          <div className={css.imageLast}>
            <span>
              The 2 maps as presented above are in A3 The Southbank view is a
              panoramic map and so will need to go to specialist printers that
              will cut to size. The maps are unique to Triple Tree Tours and
              cover the areas and topics discussed on the tours
            </span>
          </div>
        </div>
        <div className={css.text}>
          <h1>City & Southbank London: 45AD - 1553</h1>
          <h6>
            The maps above are of the immediate territory occupied by the
            Romans, the Britannic's, and by prostitutes from and to the time as
            illustrated. The Southbank was mainly brothels until the dissolution
            of the monasteries in the period 1553 - 1556
          </h6>
        </div>
      </div>
    </div>
  );
};

export default Map3;
