import React from "react";
import css from "./TsCs.module.scss";
import Nbar from "../../nbar/Nbar";
const TsCs = () => {
  return (
    <div className={css.wrapper}>
      <div className={css.navArea}>
        <Nbar />
      </div>
      <div className={css.heading}>
        <h1>Terms & Conditions</h1>
      </div>
      <div className={css.textContent}>
        <div className={css.image}>{/* <img src={img} alt="" /> */}</div>
        <div className={css.content}>
          <div className={css.textBody}>
            <div className={css.textBox}>
              1. The Company Triple Tree Tours Ltd. reserves the right to
              conduct tours and their content in accordance with the laws and
              regulations of the United Kingdom. This includes Public
              Liabilities, Censorship and various Public Order Acts
            </div>
            <div className={css.textBox}>
              2. The Guide is not liable to answer any political or potentially
              sensitive question seeking a personal opinion.
            </div>
            <div className={css.textBox}>
              3. The content of the Adults Only tours are of an adult nature
              with sexual content and this is to be understood by the buyer
              before the purchase. Complaints thereafter WILL NOT be taken
              seriously by Triple Tree Tours Ltd, and will be duly disregarded.
            </div>
            <div className={css.textBox}>
              4. The Scheduled Tours are not subject to bespoke tailoring - nor
              can any pick ups be arranged.
            </div>
            <div className={css.textBox}>
              5. The tours are not designed for the unfit or the obese. Some of
              the content of the tours is available in books and on the Hop
              On/Hop Off bus tours.
            </div>
            <div className={css.textBox}>
              6. The tour content is delivered using swear words and cant from
              the ages to present and this is to be understood. The delivery of
              the realities of the poor in the past is the company policy and
              this is to be understood by the buyer. Any objection to this mode
              of delivery during the tour will result in the participant being
              asked to leave.
            </div>
            <div className={css.textBox}>
              7. Hand Outs will be given out during the tour. The posters will
              be sent as downloadable PDFs or JPEGS within 1 Month of tour.
            </div>
            <div className={css.textBox}>
              8. The Guide will be taking group photographs for social media
              throughout the tour and will be posting on the various platforms
              as chosen. This is to be understood by the buyer before purchase.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TsCs;
