import React from "react";
import css from "./Map4.module.scss";
import img1 from "../../../../assets/img/maps/map4img1.png";
import img2 from "../../../../assets/img/maps/map4img2.png";
import img3 from "../../../../assets/img/maps/map4img3.png";
import img4 from "../../../../assets/img/maps/map4img4.png";
const Map4 = () => {
  return (
    <div className={css.wrapper}>
      <div className={css.mainContent}>
        <div className={css.images}>
          <div className={css.image}>
            <img src={img1} alt="" />
            <h5>Covent Garden 1750s</h5>
          </div>
          <div className={css.image}>
            <img src={img2} alt="" />
            <h5>Holywell Street 1900</h5>
          </div>
          <div className={css.image}>
            <img src={img3} alt="" />
            <h5>Cottaging & Toilet Trading Map of London 1933</h5>
          </div>
          <div className={css.image}>
            <img src={img4} alt="" />
            <h5>Soho 1970s</h5>
          </div>
        </div>
        <div className={css.text}>
          <h1>Sexual London: 1750s - 1970s</h1>
          <h6>
            Covent Garden was the home of the bestseller John Harris Guide to
            Covent Garden Ladies (1765). Holywell Street was a Red Light
            District until 1910 when Kingsway was built. The map of Gents
            toilets and spaces of illicit assignation is from the 1930s.
            Shoreditch now, and not Soho is gaining ground as an LGBTQI space.
            All A3
          </h6>
        </div>
      </div>
    </div>
  );
};

export default Map4;
