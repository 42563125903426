const ExecutorsData2 = [
  {
    id: 1,
    title: "William Marvel",
    descrip: "1715 - 1717",
  },
  {
    id: 2,
    title: "Banks the Bailiff",
    descrip: "1717 - 1718",
  },
  {
    id: 3,
    title: "Richard Arnel",
    descrip: "1720 - 1728",
  },
  {
    id: 4,
    title: "John Hooper",
    descrip: "1728 - 1735",
  },
  {
    id: 5,
    title: "John Thrift",
    descrip: "1735 - 1752",
  },
  {
    id: 6,
    title: "John Thurlis",
    descrip: "1752 - 1771",
  },
  {
    id: 7,
    title: "Edward Dennis",
    descrip: "1771 - 1786",
  },
  {
    id: 8,
    title: "James Botting",
    descrip: "1813 - 1819",
  },
  {
    id: 9,
    title: "Thomas Cheshire",
    descrip: "1820 - 1829",
  },
  {
    id: 10,
    title: "James Foxen",
    descrip: "1820 - 1829",
  },
];
export default ExecutorsData2;
