import React from "react";
import css from "./Avijit.module.scss";
import { useNavigate } from "react-router-dom";
const Avijit = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/avijit-contacts");
  };
  return (
    <div className={css.wrapper}>
      <div className={css.content}>
        <h3 onClick={handleClick}>
          This Website is designed with Love by Avijit Mondal
        </h3>
      </div>
    </div>
  );
};

export default Avijit;
