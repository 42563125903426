import React from "react";
import css from "./GreatFireLondon.module.scss";
import img1 from "../../../assets/img/Immersive/greatfire1.png";
import img2 from "../../../assets/img/Immersive/greatfire2.png";
import img3 from "../../../assets/img/Immersive/greatfire3.png";
import ImmerNav from "../immersiveTours/ImmerNav";
import { useNavigate } from "react-router-dom";
import book from "../../../../src/assets/img/bk.png";

const GreatFireLondon = () => {
  const navigate = useNavigate();
  const navigateOrderPage = () => {
    navigate("/checkout");
  };
  return (
    <div className={css.wrapper}>
      <div className={css.navArea}>
        <ImmerNav />
      </div>
      <div className={css.body}>
        <div className={css.upperBody}>
          <div className={css.upperBodyLeft}>
            <img src={img1} alt="" />
          </div>
          <div className={css.upperBodyRight}>
            <div>
              <h1>Triple Tree Tours</h1>
              <h2>Great Fire of London Walking Tour</h2>
            </div>
            <div>
              <h6>
                <span style={{ fontWeight: "bold" }}>Description:</span>{" "}
                <span style={{ textDecoration: "underline" }}>Adult Only </span>{" "}
                tour of The Great Fire of London takes the participant through
                the fire, covering it's physical day by day journey. The
                narrative starts with London in September 1666. While walking,
                the narrative further covers the structural damage of the fire,
                the emotional responses, the costs, the events, the
                investigations, the main players, the theories and the
                aftermath. We end the walk at a traditional London pub. The tour
                is 3 hours. <h6>Meeting Place: Monument</h6>
              </h6>
            </div>
          </div>
        </div>

        <div className={css.middleBody}>
          <div className={css.middleLeft}>
            <img src={img2} alt="" />
          </div>
          <div className={css.middleMiddle}>
            <div className={css.text1}>
              <h1>What is NOT Included</h1>
              <h6> Food & Drink</h6> <h6>Waterproofs</h6>{" "}
              <h6> Refunds or Tips</h6>
            </div>
            <div className={css.text2}>
              <h1>What IS INCLUDED</h1>
              <h6>Costumed Professional Guide</h6>
              <h6> Free Goods & Services from Triple Tree Tours </h6>
              <h6>Lifetime Access to Guide & After Sales Service </h6>
              <h6>Q&A for duration of tour</h6>
            </div>
          </div>
          <div className={css.middleRight}>
            <img src={img3} alt="" />
          </div>
        </div>

        <div className={css.bottomBody}>
          <div className={css.bottomBodyLeft}>
            <span>Dates for 2023</span>
            <h4 style={{ fontWeight: "bold" }}>May: 12, 26</h4>
            <h4 style={{ fontWeight: "bold" }}>June: 4</h4>
            <h4 style={{ fontWeight: "bold" }}>July: 14, 21, 28</h4>
            <h4 style={{ fontWeight: "bold" }}>August: 11, 18</h4>
            <h4 style={{ fontWeight: "bold" }}>September: 2, 29</h4>
            <h4 style={{ fontWeight: "bold" }}>October: 1, 29</h4>
          </div>
          <div className={css.bottomBodyRight}>
            <h4 style={{ fontWeight: "bold" }}>
              BOOK NOW TO AVOID DISAPPOINTMENT
            </h4>
            <h4 style={{ fontWeight: "bold" }}>£55.00 ONLY</h4>
            <h4 style={{ fontWeight: "bold" }}>3 HOURS</h4>
            <h4>Friday Start: 5pm</h4>
            <h4>Sat/Sun Start 1pm</h4>
            <h4 style={{ fontWeight: "bold" }}>Date</h4>
            <h4 style={{ fontWeight: "bold" }}>Number of Participants</h4>
            <div className={css.checkoutArea}>
              <div className={css.checkout}>
                <div className={css.paymentBtn}>
                  <img style={{ width: '10rem' }} src={book} alt="book now" onClick={navigateOrderPage} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GreatFireLondon;
