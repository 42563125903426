const HistoricDataTwo = [
  {
    id: 1,
    victim: "1222: Constantine Fitzathulf",
    crime: "Rebel/Treason",
    fate: "Hung, Drawn & Quartered at Tyburn",
  },
  {
    id: 2,
    victim: "1242: William De Marisco",
    crime: "Murder",
    fate: "Hung, Drawn & Quartered at Tyburn",
  },
  {
    id: 3,
    victim: "1295: Sir Thomas De Turbeville",
    crime: "Treason",
    fate: "Hung, Drawn & Quartered at Tyburn",
  },
];
export default HistoricDataTwo;
