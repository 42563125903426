import React from "react";
import css from "./HistoricCase.module.scss";
import Nbar from "../../nbar/Nbar";
import HistoricDataOne from "./historicCaseDataOne";
import HistoricDataTwo from "./historicCaseDataTwo";
import HistoricDataThree from "./historicCaseDataThree";
import HistoricDataFour from "./historicCaseDataFour";
import HistoricDataFive from "./HistoricCaseDataFive";
import HistoricDataSix from "./HistoricCaseDataSix";
import HistoricDataSeven from "./HistoricCaseDataSeven";
import HistoricDataEight from "./HistoricCaseDataEight";
import HistoricDataNine from "./HistoricCaseDataNine";
const HistoricCase = () => {
  return (
    <div className={css.wrapper}>
      <div className={css.navArea}>
        <Nbar />
      </div>
      <div className={css.header}>
        <div className={css.headerContent}>
          <h1>Triple tree tours list of London victims</h1>
          <h6>
            This is a comprehensive list of London Victims of either cruel and
            harsh punishments or the death penalty. It should be used either as
            reference on its own or in conjunction with other areas of the site
            and Tours. It is worth looking up some of the poor unfortunates in
            this list - but there will be some of their stories told on the
            tours themselves. Look at the various punishments and deaths on
            other sections of the site; here the victims are listed in
            chronological order. There has been an estimated of around 70k
            deaths at Tyburn, the area now being developed as a site for a
            luxury hotel and residents complex. Some of the more popular
            victims' deaths drew crowds between 20 and 100 thousand.
          </h6>
        </div>
      </div>
      <div className={css.body}>
        <div className={css.bodyContent}>
          <div className={css.content}>
            <table className={css.table}>
              <thead>
                <tr>
                  <th>VICTIM</th>
                  <th>CRIME</th>
                  <th>FATE</th>
                </tr>
              </thead>
              <tbody>
                {" "}
                <h1>12th century</h1>
                {HistoricDataOne.map((HistoricDataOne) => (
                  <tr key={HistoricDataOne.id}>
                    <td>{HistoricDataOne.victim}</td>
                    <td>{HistoricDataOne.crime}</td>
                    <td>{HistoricDataOne.fate}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className={css.content}>
            <table className={css.table}>
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {" "}
                <h1>13th century</h1>
                {HistoricDataTwo.map((HistoricDataTwo) => (
                  <tr key={HistoricDataTwo.id}>
                    <td>{HistoricDataTwo.victim}</td>
                    <td>{HistoricDataTwo.crime}</td>
                    <td>{HistoricDataTwo.fate}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className={css.content}>
            <table className={css.table}>
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {" "}
                <h1>14th century</h1>
                {HistoricDataThree.map((HistoricDataThree) => (
                  <tr key={HistoricDataThree.id}>
                    <td>{HistoricDataThree.victim}</td>
                    <td>{HistoricDataThree.crime}</td>
                    <td>{HistoricDataThree.fate}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className={css.content}>
            <table className={css.table}>
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {" "}
                <h1>15th century</h1>
                {HistoricDataFour.map((HistoricDataFour) => (
                  <tr key={HistoricDataFour.id}>
                    <td>{HistoricDataFour.victim}</td>
                    <td>{HistoricDataFour.crime}</td>
                    <td>{HistoricDataFour.fate}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className={css.content}>
            <table className={css.table}>
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {" "}
                <h1>16th century</h1>
                {HistoricDataFive.map((HistoricDataFive) => (
                  <tr key={HistoricDataFive.id}>
                    <td>{HistoricDataFive.victim}</td>
                    <td>{HistoricDataFive.crime}</td>
                    <td>{HistoricDataFive.fate}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className={css.content}>
            <table className={css.table}>
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {" "}
                <h1>17th century</h1>
                {HistoricDataSix.map((HistoricDataSix) => (
                  <tr key={HistoricDataSix.id}>
                    <td>{HistoricDataSix.victim}</td>
                    <td>{HistoricDataSix.crime}</td>
                    <td>{HistoricDataSix.fate}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className={css.content}>
            <table className={css.table}>
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {" "}
                <h1>18th century</h1>
                {HistoricDataSeven.map((HistoricDataSeven) => (
                  <tr key={HistoricDataSeven.id}>
                    <td>{HistoricDataSeven.victim}</td>
                    <td>{HistoricDataSeven.crime}</td>
                    <td>{HistoricDataSeven.fate}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className={css.content}>
            <table className={css.table}>
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {" "}
                <h1>19th century</h1>
                {HistoricDataEight.map((HistoricDataEight) => (
                  <tr key={HistoricDataEight.id}>
                    <td>{HistoricDataEight.victim}</td>
                    <td>{HistoricDataEight.crime}</td>
                    <td>{HistoricDataEight.fate}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className={css.content}>
            <table className={css.table}>
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {" "}
                <h1>20th century</h1>
                {HistoricDataNine.map((HistoricDataNine) => (
                  <tr key={HistoricDataNine.id}>
                    <td>{HistoricDataNine.victim}</td>
                    <td>{HistoricDataNine.crime}</td>
                    <td>{HistoricDataNine.fate}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistoricCase;
