import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./ImmerNav.css";
const ImmerNav = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  return (
    <nav>
      <div className="wrap">
        <div className={click ? "nbar-menu active" : "nbar-menu"}>
          <ul>
            {" "}
            <li className="nbarList">
              <Link className="nbarLink" to="/">
                Back to <br /> Homepage
              </Link>
            </li>
            <li className="nbarList">
              <Link className="nbarLink" to="/tyburn-tour-page">
                Tyburn: Death
                & Punishment
              </Link>
            </li>
            <li className="nbarList">
              <Link className="nbarLink" to="/lgbtqia-tour-page">
                LGBTQI 45AD <br />
                to Present
              </Link>
            </li>
            <li className="nbarList">
              <Link className="nbarLink" to="/sin-sex-in-london">
                Sin & Sex in <br />
                in London
              </Link>
            </li>
            <li className="nbarList">
              <Link className="nbarLink" to="/great-fire-of-london">
                Great Fire of <br />
                London
              </Link>
            </li>
            <li className="nbarList">
              <Link className="nbarLink" to="/ales-coffeehouse-pubs">
                Ales, Coffee
                <br />
                Houses & Pubs
              </Link>
            </li>
            <li className="nbarList">
              <Link className="nbarLink" to="/immersive-tours">
                Immersive <br />
                Tours
              </Link>
            </li>
          </ul>
        </div>

        {/* <div className={css.hamburgerMenu} onClick={handleShowNavbar}>
      <button className={css.hamburger} onClick={handleShowNavbar}>
        <GiHamburgerMenu />
      </button>
      
    </div> */}

        <div className="nbarmenu-icon" onClick={handleClick}>
          <i
            className={click ? "fas fa-times" : "fas fa-bars"}
            style={{ fonstSize: "2rem" }}
          />
        </div>
      </div>
    </nav>
  );
};

export default ImmerNav;
