import React from "react";
import css from "./ImmersiveTourOne.module.scss";
import img1 from "../../../../assets/img/tour/image2.png";

import book from '../../../../assets/img/bk.png'
import { useNavigate } from "react-router-dom";
import img2 from "../../../../assets/img/tour/tour2.png";

import tourmap from '../../../../assets/img/tour/tourMap2.png'
// import TourRoutesNav from "../TourRoutesNav";
const ImmersiveTourOne = () => {
  const navigate = useNavigate();  
  const navigateOrderPage = () => {
    navigate("/checkout");
  };
  return (
    <div className={css.wrapper}>
      {/* <div className={css.navArea}>
      <TourRoutesNav />
      </div> */}

      <div className={css.container}>
        <div className={css.top}>
          <div className={css.content}>
            <h1 style={{textAlign:'center'}}>Booking Immersive Tours</h1>
            <h5>Each of the tours, and there are five in total - are ADULT
              ONLY. They cover very adult material and the detail of these
              suitable for over 18 or even over 21. Corporate Tours can be
              booked all year for <span style={{ fontStyle: "italic" }}>Weekday Evening</span></h5>
          </div>
        </div>
        <div className={css.middle}>
          <div className={css.leftpart}>
            <img className={css.img} src={img1} alt="" />
            <div className={css.text}>
              <span>£55 BOOK <br />
                3 Hours<br />
                Great Fire of<br />
                London</span>
            </div>
            <div className={css.paymentBtn}>
              <img src={book} alt="book now" onClick={navigateOrderPage} />
            </div>
          </div>
          <div className={css.middlepart}>
            <img src={tourmap} alt="" />
          </div>
          <div className={css.rightPart}>
            <div className={css.text}>
              <span>
                Meeting at Monument. We start with a
                snapshot of London in 1660's: Housing,
                Politics, Religion, Demographics, the size
                and shape of the city are talked over at
                this point. The days from 1-4 are talked
                through - with the structural, financial and
                emotional response to the fire covered in
                the journey from Monument to Lombard
                St, to St. Pauls, to Pye Corner.
                We convene at The Old Red Cow Public
                House in Smithfield's for some needed
                refreshments and food. (not Included)
              </span>
            </div>
          </div>

        </div>

        <div className={css.bottom}>
          <div className={css.bottomleft}>
            <div className={css.image}>
              <img src={img2} alt="" />
            </div>
            <div className={css.text}>
              <span>
                £110 BOOK <br />
                4 Hours<br />
                Ales, Alehouses,<br />
                Coffeehouses & Pubs<br />
              </span>
            </div>
            <div className={css.paymentBtn}>
              <img style={{ width: '7rem' , cursor:'pointer'}} src={book} alt="book now" onClick={navigateOrderPage} />
            </div>
          </div>
          <div className={css.bottomRight}>
            <div className={css.map}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d4421.603996360791!2d-0.10330932100684391!3d51.51083443773079!2m3!1f11.624999999999604!2f0!3f0!3m2!1i1024!2i768!4f35!5e1!3m2!1sen!2sin!4v1682966661375!5m2!1sen!2sin"
                width="90%"
                height="150"
                style={{ border: "0" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                title="myFrame"
                margin="auto"
              ></iframe>
            </div>
            <div className={css.text}>
              <span>We start at Southbank and talk about the Romans and what they found as an already
                established ale making tradition in Britannia. At London Bridge we go through the
                evolution of the infrastructure in the UK/London and it's relationship to Ale houses,
                drinking, Stagecoach Inns and Taverns.
                We go from here over to the City and deal with the origins of Coffeehouses and what
                they brought to London, the responses of women and the growth. We then travel to
                Fleet Street and discover how the Alehouse was the place of choice for informants,
                conspirators and criminal gatherings.
                We then go to Covent Garden and talk about the Coffee House in the 17th/18th
                Centuries in this area. We rest finally, in Whitehall.</span>
            </div>
          </div>
        </div>
      </div>

 
    </div>
  );
};

export default ImmersiveTourOne;
