import React from "react";
import css from "./Book.module.scss";
import { useNavigate } from "react-router-dom";
import Nbar from "../../nbar/Nbar";
import Avijit from "../Avijit/Avijit";
const Book = () => {
  const navigate = useNavigate();

  const navigateResources = () => {
    navigate("/historicTimeline");
  };
  const navigateExecutors = () => {
    navigate("/executorsTorturers");
  };
  const navigatePamphlets = () => {
    navigate("/pamphlets");
  };
  const navigateStuffOnSex = () => {
    navigate("/stuff-on-sex");
  };
  const navigateHistoricCase = () => {
    navigate("/historic-case");
  };
  const navigateBroadsides = () => {
    navigate("/broadsides");
  };
  const navigateBallads = () => {
    navigate("/ballads-&-murder-ballads");
  };
  const navigateChapbooks = () => {
    navigate("/chapbooks");
  };
  return (
    <div className={css.wrapper}>
      <div className={css.mainDiv}>
        <div className={css.navArea}>
          <Nbar />
        </div>

        <div className={css.textArea}>
          <h1>Resources</h1>
          <h4>(Click on Box for Information & Access)</h4>
        </div>
        <div className={css.contentArea}>
          <div className={css.contentLeft}>
            <div className={css.content}>
              <div className={css.contentText}>
                {" "}
                <h1 onClick={navigateResources}>Historic Timeline</h1>
              </div>
            </div>
            <div className={css.content}>
              {" "}
              <div className={css.contentText} onClick={navigateBroadsides}>
                <h1>Broadsides</h1>
              </div>
            </div>
            <div className={css.content} onClick={navigateHistoricCase}>
              {" "}
              <div className={css.contentText}>
                <h1>Historic Case Histories</h1>
              </div>
            </div>
            <div className={css.content} onClick={navigateChapbooks}>
              {" "}
              <div className={css.contentText}>
                <h1>Chapbooks</h1>
              </div>
            </div>
          </div>
          <div className={css.contentRight}>
            <div className={css.content} onClick={navigateExecutors}>
              {" "}
              <div className={css.contentText}>
                <h1>Executors & Torturers</h1>
              </div>
            </div>
            <div className={css.content}>
              {" "}
              <div className={css.contentText} onClick={navigateBallads}>
                <h1>Ballads</h1>
              </div>
            </div>
            <div className={css.content} onClick={navigatePamphlets}>
              {" "}
              <div className={css.contentText}>
                <h1>Pamphlets</h1>
              </div>
            </div>
            <div className={css.content} onClick={navigateStuffOnSex}>
              {" "}
              <div className={css.contentText}>
                <h1>Stuff on Sex</h1>
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
      <Avijit />
    </div>
  );
};

export default Book;
