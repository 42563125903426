import React from "react";
import css from "./ImmersiveTourTwo.module.scss";
// import TourRoutesNav from "../TourRoutesNav";
import img1 from "../../../../assets/img/Immersive/sinsex1.png";
import tourmap from '../../../../assets/img/tour/tourMap2.png'
import img3 from "../../../../assets/img/Immersive/lgbtqia1.png";
import { useNavigate } from "react-router-dom";
import book from '../../../../assets/img/bk.png'

const ImmersiveTourTwo = () => {
  const navigate = useNavigate();
  const navigateOrderPage = () => {
    navigate("/checkout");
  };
  return (
    <div className={css.wrapper}>
      {/* <div className={css.navBar}>
        <TourRoutesNav />
      </div> */}
      <div className={css.contentArea}>
        <div className={css.upperContetn}>
          <div className={css.ucLeft}>
            <div className={css.ucLeftImg}>
              <img src={img1} alt="" />
            </div>
            <div className={css.ucLeftText}>
              <span>£120</span><br />
              <span>BOOK</span><br />
              <span>Sin, Sex, & Vice</span><br />
              <span>in London</span><br />
              <span>5 Hours</span><br />
            </div>
            <div className={css.paymentBtn}>
              <img style={{ width: '7rem', display: 'flex', margin: 'auto' }} src={book} alt="book now" onClick={navigateOrderPage} />
            </div>
          </div>
          <div className={css.ucRight}>
            <div className={css.ucRightImg}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d4421.603996360791!2d-0.10330932100684391!3d51.51083443773079!2m3!1f11.624999999999604!2f0!3f0!3m2!1i1024!2i768!4f35!5e1!3m2!1sen!2sin!4v1682966661375!5m2!1sen!2sin"
                width="100%"
                height="250"
                style={{ border: "0" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                title="myFrame"
              ></iframe>
            </div>
            <div className={css.ucRightText}>
              <span>
                Covers the subversive areas of Pimping, Prostitution &
                Pornography from the Roman Invasion to the present day, though
                the 20th Century and Soho is covered in pub chat. We start
                with the Romans on Southbank, and cover all prostitution in
                this area through the ages. In the city we discuss Anglo Saxon
                punishments and values. We talk about double standards, whore
                hierarchies and relationships between church/sex, militia/sex.
                Continuing along Fleet St/Strand, we go into sexual
                pamphleteering, pornography and the original Red Light
                district/BDSM culture. We look into Covent Garden and the
                ladies of the night in the 18th Century. We go into the heart
                of Theatre land and talk about Procuring, Virgins and the
                History of Syphilis and cures. We decamp to a West End Pub.
              </span>
            </div>
          </div>
        </div>
        <div className={css.bottomContent}>
          <div className={css.bottomLeft}>
            <div className={css.bottomLeftImg}>
              <img src={img3} alt="" />
            </div>
            <div className={css.bottomLeftText}>
              <span>£110<br />
                BOOK<br />
                LGBTQIA<br />
                4 Hours</span>
            </div>
            <div className={css.paymentBtn}>
              <img style={{ width: '7rem' }} src={book} alt="book now" onClick={navigateOrderPage} />
            </div>
          </div>
          <div className={css.bottomMiddle}>
            <div></div>
            <img src={tourmap} alt="" />
          </div>

          <div className={css.bottomRight}>

            <span>
              In context, covers the legal, cultural and sexual history of the
              LGBTQIA movement from the Roman Invasion to the present day. We
              start at Southbank and go over the mores and values of
              Viking/Anglo Saxon/Pre Christian cultures.
              <br />
              Moving from here we move to Westminster and cover the legal
              history. We cover political scandals and the use of blackmail in
              the professional classes. Then onto St. James where we talk
              about Royals/Courtesans and attitudes to cross dressing in high
              society. We go from here into the West End and talk about the
              importance of LBGTQI to modern pop culture and it's influence on
              English music. We end in a Soho LGBTQI landmark pub.
            </span>

          </div>
        </div>
      </div>

    </div>
  );
};

export default ImmersiveTourTwo;
