const HistoricDataSix = [
  {
    id: 1,
    victim: "1601: Robert Devereux (Earl)",
    crime: "Treason",
    fate: "awdaBeheaded at The Towerwwd",
  },
  {
    id: 2,
    victim: "1601: Christopher Blout",
    crime: "Treason",
    fate: "Beheaded at The Tower",
  },
  {
    id: 3,
    victim: "1606: The Gunpowder Plotters",
    crime: " ",
    fate: "Castrated/then",
  },
  {
    id: 4,
    victim: "Everard Digby/Robert Wintour",
    crime: " ",
    fate: "Hung, Drawn & Quartered",
  },
  {
    id: 5,
    victim: "John Grant/Thomas Bates",
    crime: "Treason",
    fate: "at St. Paul's Churchyard",
  },
  {
    id: 6,
    victim: "1606: The Gunpowder Plotters",
    crime: " ",
    fate: "Castrated/then",
  },
  {
    id: 7,
    victim: "Thomas Wintour/Ambrose Rockwood",
    crime: " ",
    fate: "Hung, Drawn & Quartered",
  },
  {
    id: 8,
    victim: "Robert Keyes/Guy Fawkes",
    crime: "Treason",
    fate: "at Old Palace Yard, Westminster",
  },
  {
    id: 9,
    victim: "1606: Henry Garnet",
    crime: "Treason",
    fate: "Hung, Drawn & Quartered at Tyburn",
  },
  {
    id: 10,
    victim: "1615: Anne Turner",
    crime: "Murder",
    fate: "Hung at Tyburn",
  },
  {
    id: 11,
    victim: "1615: Thomas Helwys",
    crime: "Sedition",
    fate: "Died in Newgate B4 Hanging",
  },
  {
    id: 12,
    victim: "1615: Gervase Helwys",
    crime: "Murder",
    fate: "Hung at Tyburn",
  },
  {
    id: 13,
    victim: "1621: Elizabeth Sawyer",
    crime: "Witchcraft",
    fate: "Hung at Tyburn",
  },
  {
    id: 14,
    victim: "1621: Edward Floyd",
    crime: "Sedition",
    fate: "Branded in Cheapside/Whipped",
  },
  {
    id: 15,
    victim: "1631: Earl of Castlehaven",
    crime: "Sodomy",
    fate: "Beheaded at The Tower",
  },
  {
    id: 16,
    victim: "1640: Isaac Atkinson",
    crime: "Highway Robbery",
    fate: "Hung at Tyburn",
  },
  {
    id: 17,
    victim: "1641: Thomas Wentworth",
    crime: "Treason",
    fate: "Beheaded at The Tower",
  },
  {
    id: 18,
    victim: "1642: Edward Morgan",
    crime: "Heresy",
    fate: "Hung at Tyburn",
  },
  {
    id: 19,
    victim: "1645: William Land",
    crime: "Treason",
    fate: "Beheaded at The Tower",
  },
  {
    id: 20,
    victim: "1651: Peter Wright",
    crime: "Heresy",
    fate: "Hung, Drawn & Quartered at Tyburn",
  },
  {
    id: 21,
    victim: "1658: Edward Aston",
    crime: "Rebel",
    fate: "Hung, Drawn & Quartered at The Tower",
  },
  {
    id: 22,
    victim: "1660: The Regicides (Dead)",
    crime: "Treason",
    fate: "Hung, Drawn & Quartered at Tyburn",
  },
  {
    id: 23,
    victim: "Henry Ireton/Oliver Cromwell",
    crime: " ",
    fate: " ",
  },
  {
    id: 24,
    victim: "John Bradsaw/John Pym",
    crime: " ",
    fate: " ",
  },
  {
    id: 25,
    victim: "1660: The Regicides (Alive)",
    crime: "Treason",
    fate: "Hung, Drawn & Quartered at Charing X",
  },
  {
    id: 26,
    victim: "Thomas Harrison/Adrian Scrope",
    crime: " ",
    fate: " ",
  },
  {
    id: 27,
    victim: "Owen Rowe/Augustine Garland",
    crime: " ",
    fate: " ",
  },
  {
    id: 28,
    victim: "Vincent Potter/Simon Mayne",
    crime: " ",
    fate: " ",
  },
  {
    id: 29,
    victim: "George Fleetwood",
    crime: " ",
    fate: " ",
  },
  {
    id: 30,
    victim: "1662: Henry Vane",
    crime: "Treason",
    fate: "Beheaded at The Tower",
  },
  {
    id: 31,
    victim: "1664: Sawney Douglas",
    crime: "Highway Robbery",
    fate: "Hung at Tyburn",
  },
  {
    id: 32,
    victim: "1666: Robert Hubert",
    crime: "False Confession",
    fate: "Body Torn Apart by Crowd at Tyburn",
  },
  {
    id: 33,
    victim: "1670: Claude Du Val",
    crime: "Highway Robbery",
    fate: "Hanged at Tyburn",
  },
  {
    id: 34,
    victim: "1673: Mary Carleton",
    crime: "Fraudster/Bigamist",
    fate: "Hung at Tyburn",
  },
  {
    id: 35,
    victim: "1678: Robert Foulkes",
    crime: "Child Killer",
    fate: "Hanged at Tyburn",
  },
  {
    id: 36,
    victim: "1681: Oliver Plunkett",
    crime: "Rebel",
    fate: "Hung, Drawn & Quartered at Tyburn",
  },
  {
    id: 37,
    victim: "1683: Algernon Sydney",
    crime: "Treason",
    fate: "Beheaded at The Tower",
  },
  {
    id: 38,
    victim: "1683: The Rye House Plotters",
    crime: "Treason",
    fate: "Hung, Drawn & Quartered at The Tower",
  },
  {
    id: 39,
    victim: "Thomas Armstrong/John Aylotte",
    crime: " ",
    fate: " ",
  },
  {
    id: 40,
    victim: "Henry Cornish/James Halloway",
    crime: " ",
    fate: " ",
  },
  {
    id: 41,
    victim: "Richard Rumbold/Thomas Walcott",
    crime: " ",
    fate: " ",
  },
  {
    id: 42,
    victim: "John Rouse",
    crime: " ",
    fate: " ",
  },
  {
    id: 43,
    victim: "1683: The Rye House Plotters",
    crime: " ",
    fate: " ",
  },
  {
    id: 44,
    victim: "William Russell/Algeman Sidney",
    crime: "Treason",
    fate: "Beheaded at Lincoln's Inn",
  },
  {
    id: 45,
    victim: "Richard Nelthorpe",
    crime: " ",
    fate: "Hung at Gray's Inn",
  },
  {
    id: 46,
    victim: "1685: Elizabeth Gaunt",
    crime: "Treason",
    fate: "Burned at the Stake at Smithfield",
  },
  {
    id: 47,
    victim: "1685: James Scott (Duke)",
    crime: "Treason",
    fate: "Beheaded at The Tower",
  },
  {
    id: 48,
    victim: "1690: Sir John Johnson",
    crime: "Swindler",
    fate: "Hung at Tyburn",
  },
  {
    id: 49,
    victim: "1699: William Chaloner",
    crime: "Counterfeiter",
    fate: "Hung at Tyburn",
  },
];

export default HistoricDataSix;
