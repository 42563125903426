import React from "react";
import css from "./AlesAlehouses.module.scss";
import img1 from "../../../assets/img/Immersive/alesale1.png";
import img2 from "../../../assets/img/Immersive/alesale2.png";
import img3 from "../../../assets/img/Immersive/alesale3.png";
import ImmerNav from "../immersiveTours/ImmerNav";
import { useNavigate } from "react-router-dom";
import book from "../../../../src/assets/img/bk.png";
const AlesAlehouses = () => {
  const navigate = useNavigate();
  const navigateOrderPage = () => {
    navigate("/checkout");
  };
  return (
    <div className={css.wrapper}>
      <div className={css.navArea}>
        <ImmerNav />
      </div>
      <div className={css.body}>
        <div className={css.upperBody}>
          <div className={css.upperBodyLeft}>
            <img src={img1} alt="" />
          </div>
          <div className={css.upperBodyRight}>
            <div>
              <h1>Triple Tree Tours Walking Tour</h1>
              <h2>Ales, Alehouses, Coffeehouses & Pubs</h2>
            </div>
            <div>
              <h6>
                <span style={{ fontWeight: "bold" }}>Description:</span>{" "}
                <span style={{ textDecoration: "underline" }}>Adult Only </span>{" "}
                tour of drinking culture in London and England is an historic
                tour with pub visits. We start with the Southbank and the Roman
                and Viking Invasions. We walk along the river and discuss the
                rise of the Alehouse and the relationship between drinking and
                sex. We move to a Coaching Inn along the way to the City where
                we talk about the emergence of the Coffeehouse. We continue
                along Fleet St and stop off at a pub for some pub stories. The
                tour is 5 hours. <br />{" "}
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  Meeting Place: The Globe
                </span>
              </h6>
            </div>
          </div>
        </div>

        <div className={css.middleBody}>
          <div className={css.middleLeft}>
            <img src={img2} alt="" />
          </div>
          <div className={css.middleMiddle}>
            <div className={css.text1}>
              <h1>What is NOT Included</h1>
              <h6> Food & Drink</h6> <h6>Waterproofs</h6>{" "}
              <h6> Refunds or Tips</h6>
            </div>
            <div className={css.text2}>
              <h1>What IS INCLUDED</h1>
              <h6>Costumed Professional Guide</h6>
              <h6> Free Goods & Services from Triple Tree Tours </h6>
              <h6>Lifetime Access to Guide & After Sales Service </h6>
              <h6>Q&A for duration of tour</h6>
            </div>
          </div>
          <div className={css.middleRight}>
            <img src={img3} alt="" />
          </div>
        </div>

        <div className={css.bottomBody}>
          <div className={css.bottomBodyLeft}>
            <span>Dates for 2023</span>
            <h4 style={{ fontWeight: "bold" }}>May: 21, 28</h4>
            <h4 style={{ fontWeight: "bold" }}>June: 11, 18</h4>
            <h4 style={{ fontWeight: "bold" }}>July: 1, 2</h4>
            <h4 style={{ fontWeight: "bold" }}>August: 4, 12, 26</h4>
            <h4 style={{ fontWeight: "bold" }}>September: 15, 22</h4>
            <h4 style={{ fontWeight: "bold" }}>October: 6, 13</h4>
          </div>
          <div className={css.bottomBodyRight}>
            <h4 style={{ fontWeight: "bold" }}>
              BOOK NOW TO AVOID DISAPPOINTMENT
            </h4>
            <h4 style={{ fontWeight: "bold" }}>£100.00</h4>
            <h4 style={{ fontWeight: "bold" }}>5 HOURS</h4>
            <h4>Friday Start: 4pm</h4>
            <h4>Sat/Sun Start 1pm</h4>
            <h4 style={{ fontWeight: "bold" }}>Date</h4>
            <h4 style={{ fontWeight: "bold" }}>Number of Participants</h4>
            <div className={css.checkoutArea}>
              <div className={css.checkout}>
                <div className={css.paymentBtn}>
                  <img style={{ width: '10rem' }} src={book} alt="book now" onClick={navigateOrderPage} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlesAlehouses;
