import React, { useState, useEffect } from "react";
import css from "./ImmerTours.module.scss";
import { useNavigate } from "react-router-dom";
import Nbar from "../../nbar/Nbar";
// import DownloadZip from "../downloadZip/DownloadZip";

const ImmerTours = () => {
  const [color, setColor] = useState("red");
  useEffect(() => {
    const interval = setInterval(() => {
      setColor((color) => (color === "red" ? "yellow" : "red"));
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  const navigate = useNavigate();
  const navigateTyburn = () => {
    navigate("/tyburn-tour-page");
  };
  const navigateLgbti = () => {
    navigate("/lgbtqia-tour-page");
  };

  const navigateSinSex = () => {
    navigate("/sin-sex-in-london");
  };
  const navigateGreatFire = () => {
    navigate("/great-fire-of-london");
  };
  const navigateAlesHouse = () => {
    navigate("/ales-coffeehouse-pubs");
  };
  const navigateTourPage = () => {
    navigate("/tour-routes");
  };
  return (
    <div className={css.wrapper}>
      <div className={css.navArea}>
        <Nbar />
      </div>
      <div className={css.textArea}>
        <h1 style={{ color }}>Booking Immersive Tours</h1>
        <h4>(Click on Box for Information & Access)</h4>
      </div>

      <div className={css.contentArea}>
        {" "}
        <div className={css.contentLeft}>
          <div className={css.content} onClick={navigateTyburn}>
            <div className={css.contentText}>
              <h1 style={{ marginTop: "2.5rem" }}>
                Tyburn: Death <br /> & Punishment
              </h1>
            </div>
          </div>
          <div className={css.content} onClick={navigateLgbti}>
            {" "}
            <div className={css.contentText}>
              <h1>
                LGBTQI 45AD <br></br>to Present
              </h1>
            </div>
          </div>
          <div className={css.content} onClick={navigateSinSex}>
            <div className={css.contentText}>
              <h1>
                Sin & Sex in <br /> in London
              </h1>
            </div>
          </div>
        </div>
        <div className={css.contentRight}>
          <div className={css.content} onClick={navigateGreatFire}>
            {" "}
            <div className={css.contentText}>
              <h1>
                Great Fire of <br /> London
              </h1>
            </div>
          </div>
          <div className={css.content} onClick={navigateAlesHouse}>
            <div className={css.contentText}>
              <h1>
                Ales, Coffee <br />
                Houses & Pubs
              </h1>
            </div>
          </div>
          <div className={css.content} onClick={navigateTourPage}>
            <div className={css.contentText}>
              <h1>
                Tour <br />
                Routes
              </h1>
            </div>
          </div>
        </div>
      </div>
      {/* <div className={css.bottom}>
        <DownloadZip />
      </div> */}
    </div>
  );
};

export default ImmerTours;
