import React from "react";
import css from "./LogoSlide.module.scss";
import { useNavigate } from "react-router-dom";
import gift from "../../assets/img/logoslide/gifts.png";
import tour from "../../assets/img/logoslide/tours.png";
import resource from "../../assets/img/logoslide/resource.png";

const LogoSlide = () => {
  const navigate = useNavigate();

  const NavigateResource = () => {
    // 👇️ navigate to /
    navigate("/resources");
  };
  const NavigateImmersiveTours = () => {
    navigate("/immersive-tours");
  };
  const navigateGift = () => {
    navigate("/gifts");
  };
  return (
    <div className={css.wrapper}>
      <div className={css.textpart}>
        <div className={css.imageText}>
          <img src={gift} alt="" onClick={navigateGift} />
        </div>
        <div className={css.imageText}>
          <img src={tour} alt="" onClick={NavigateImmersiveTours} />
        </div>

        <div className={css.imageText}>
          <img src={resource} alt="" onClick={NavigateResource} />
        </div>
      </div>
    </div>
  );
};

export default LogoSlide;
