import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
const Navbar = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  return (
    <nav>
      <div className="wrapper">
        <div className={click ? "nav-menu active" : "nav-menu"}>
          <ul>
            <li className="navList">
              <Link className="navLink" to="/learning&development">
                Learning & <br /> Development
              </Link>
            </li>
            <li className="navList">
              <Link className="navLink" to="/campaigns">
                Campaigns
              </Link>
            </li>
            <li className="navList">
              <Link className="navLink" to="/resources">
                Resources
              </Link>
            </li>
            <li className="navList">
              <Link className="navLink" to="/Ts-&-Cs">
                Ts & Cs
              </Link>
            </li>
          </ul>
        </div>

        {/* <div className={css.hamburgerMenu} onClick={handleShowNavbar}>
          <button className={css.hamburger} onClick={handleShowNavbar}>
            <GiHamburgerMenu />
          </button>
          
        </div> */}

        <div className="menu-icon" onClick={handleClick}>
          <i className={click ? "fas fa-times" : "fas fa-bars"} />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
