import React from "react";
import css from "./Broadsides.module.scss";
import img1 from "../../../assets/img/broadsides/1.png";
import img2 from "../../../assets/img/broadsides/2.png";
import img3 from "../../../assets/img/broadsides/3.png";
import img4 from "../../../assets/img/broadsides/4.png";
import img5 from "../../../assets/img/broadsides/5.png";
import img6 from "../../../assets/img/broadsides/6.png";
import img7 from "../../../assets/img/broadsides/7.png";
import img8 from "../../../assets/img/broadsides/8.png";
import Nbar from "../../nbar/Nbar";
const Broadsides = () => {
  return (
    <div className={css.wrapper}>
      <div className={css.navArea}>
        <Nbar />
      </div>
      <div className={css.header}>
        <h1>Broadsides</h1>
        <h6>
          Broadsides were paper Street Literature that was popular before mass
          printing of news and gossip. They were the size and format of
          broadsheet papers and had a variety of themes. Those presented here
          represent the rich variety of subjects and types of coverage. They
          were often sensationalist in nature.
        </h6>
      </div>
      <div className={css.body}>
        <div className={css.bodyContent}>
          <div className={css.content}>
            <div className={css.image}>
              <img src={img1} alt="" />
            </div>
            <div className={css.text}>
              <h3>Wanted Poster</h3>
              <h6>
                These were used as street literature to post about randomly to
                inform, or warn the public that there was a bounty on the head
                of a criminal. This increased the stakes in the chase and
                heightened the chances of capture
              </h6>
            </div>
          </div>
          <div className={css.content}>
            <div className={css.image}>
              <img src={img2} alt="" />
            </div>
            <div className={css.text}>
              <h3>News Broadside</h3>
              <h6>
                A news broadside would circulate individual stories before the
                printing of multiple news in the print of a single edition. The
                production of a newspaper in today's form started at the
                beginning of the 18th century
              </h6>
            </div>
          </div>
          <div className={css.content}>
            <div className={css.image}>
              <img src={img3} alt="" />
            </div>
            <div className={css.text}>
              <h3>Advertising Broadside</h3>
              <h6>
                These are also known as Billboards. Advertising Broadsides have
                been used over centuries to promote a service, event or product
                available to the general public. They came in the form of
                posters before broadcast and digital ads
              </h6>
            </div>
          </div>
          <div className={css.content}>
            <div className={css.image}>
              <img src={img4} alt="" />
            </div>
            <div className={css.text}>
              <h3>Proclamations</h3>
              <h6>
                Proclamations are, or were statements or announcements from an
                authoritative source, as to how to respond or behave to
                something in society to address. Charles II issued Proclamations
                on London Coffee Houses
              </h6>
            </div>
          </div>
          <div className={css.content}>
            <div className={css.image}>
              <img src={img5} alt="" />
            </div>
            <div className={css.text}>
              <h3>Ordinances</h3>
              <h6>
                This is a regulation, a direction or set of rules that is set
                out by a government, a religion, a church or state authority. It
                can and does proffer restrictions. It can also offer directions
                or a code.
              </h6>
            </div>
          </div>
          <div className={css.content}>
            <div className={css.image}>
              <img src={img6} alt="" />
            </div>
            <div className={css.text}>
              <h3>Confessions</h3>
              <h6>
                Confessions were taken from prisoners convicted of a crime
                whilst incarcerated, and before punishment. These were then
                printed and sold as mostly sensationalist account but with the
                purpose of moral guidance
              </h6>
            </div>
          </div>
          <div className={css.content}>
            <div className={css.image}>
              <img src={img7} alt="" />
            </div>
            <div className={css.text}>
              <h3>Treatise</h3>
              <h6>
                These could be issued from anyone anywhere. They apply to a
                proposed response to something - written in a systematic and
                problem solving manner. It takes the form of initially examining
                a subject, it's nature and needs
              </h6>
            </div>
          </div>
          <div className={css.content}>
            <div className={css.image}>
              <img src={img8} alt="" />
            </div>
            <div className={css.text}>
              <h3>Crime Broadside</h3>
              <h6>
                Often issued to accompany a capital punishment and took the form
                of a public announcement. Especially relevant when executions
                were held publically. They would go over the details of the
                crime, incarceration and final solution
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Broadsides;
