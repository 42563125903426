import React, { useState } from "react";
import { PayPalButton } from "react-paypal-button-v2";
import StripeCheckout from "react-stripe-checkout";
import css from "./Checkout.module.scss";
import ImmerNav from "../immersiveTours/ImmerNav";
const Checkout = () => {
  const [price, setPrice] = useState(0);
  const [date, setDate] = useState("");

  const handlePayPalSuccess = (paymentData) => {
    // Handle successful PayPal payment
    console.log("PayPal payment successful", paymentData);
  };

  const handleStripeSuccess = (token) => {
    // Handle successful Stripe payment
    console.log("Stripe payment successful", token);
  };
  return (
    <div className={css.wrapper}>
      <div className={css.navbar}>
        <ImmerNav/>
      </div>
      <div className={css.container}>
        <h2 className={css.text1}>Checkout Page</h2>

        <div className={css.price}>
          <label>Price: 90</label>
          <input
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
        </div>

        <div className={css.duration}>
          <label>Duration: 3.5 HOURS</label>
        </div>

        <div className={css.date}>
          <label>Date:</label>
          <select value={date} onChange={(e) => setDate(e.target.value)}>
            <option value="Option A">May: 27</option>
            <option value="Option B">June: 9, 30</option>
            <option value="Option C">July: 7, 16, 30</option>
            <option value="Option D">August: 5, 27</option>
            <option value="Option E">September: 1, 17</option>
            <option value="Option F">October: 20, 28</option>
          </select>
        </div>

        <div className={css.paymentOption}>
          <h3>Payment Options:</h3>

          <PayPalButton amount={price} onSuccess={handlePayPalSuccess} />

          <StripeCheckout
            token={handleStripeSuccess}
            stripeKey="YOUR_STRIPE_PUBLIC_KEY"
            amount={price * 100} // Stripe requires the amount in cents
          />
        </div>
      </div>
    </div>
  );
};

export default Checkout;
