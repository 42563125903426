import React from "react";
import css from "./FoodDrink.module.scss";
import Nbar from "../../nbar/Nbar";
import { useNavigate } from "react-router-dom";
const FoodDrink = () => {
  const navigate = useNavigate();
  const navigateGreatFireLondon = () => {
    navigate("/great-fire-of-london");
  };
  const navigateAlesAlehouses = () => {
    navigate("/ales-alehouses-tours");
  };
  return (
    <div className={css.wrapper}>
      <div className={css.navArea}>
        <Nbar />
      </div>
      <div className={css.textArea}>
        <h1>Food & Drinks Tour</h1>
        <h6>(Click on Box for Information & Access)</h6>
      </div>
      <div className={css.contentArea}>
        <div className={css.content} onClick={navigateGreatFireLondon}>
          <div className={css.contentText}>
            <h1>Great Fire Of London</h1>
          </div>
        </div>
        <div className={css.content} onClick={navigateAlesAlehouses}>
          {" "}
          <div className={css.contentText2}>
            <h1>Tour Ales, Alehouses, Coffeehouses & Pubs</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FoodDrink;
