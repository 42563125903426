import React from "react";
import css from "./Map5.module.scss";
import img1 from "../../../../assets/img/maps/map5img1.png";
import img2 from "../../../../assets/img/maps/map5img2.png";
const Map5 = () => {
  return (
    <div className={css.wrapper}>
      <div className={css.mainContent}>
        <div className={css.images}>
          <div className={css.image}>
            <img src={img1} alt="" />
          </div>
          <div className={css.image}>
            <img src={img2} alt="" />
          </div>
        </div>
        <h6>London Thames & Lost Rivers: 850AD Greater London: 850AD</h6>
        <div className={css.text}>
          <h1>Danish Invasion London (& London Village Evolution)</h1>
          <h6>
            Both of the maps above are in A0 - which is the largest size of
            poster available and in full colour. They cost around 25-30 GDP to
            reproduce, and if interested there is a need to research for a
            printers that will print off a copy from a file (jpg or PDF). They
            are stunning and unique to Triple Tree Tours. The Lost Rivers map
            depicts the Thames and the Lost Rivers of London, not just at the
            time of the Danish Invasions, but how London looked for a
            considerable amount of time: the Greater London map shows the
            original name of each area, the modern name, the date of first
            reference, and has a symbol denoting how or what it was that founded
            the area. The result of 2.5 years of research.
          </h6>
        </div>
      </div>
    </div>
  );
};

export default Map5;
