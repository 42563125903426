import React from "react";
import css from "./Pamphlets.module.scss";
import img18plus from "../../../assets/img/pamphlets/18plus.png";
import img1 from "../../../assets/img/pamphlets/pamphlets1.png";
import img2 from "../../../assets/img/pamphlets/pamphlets2.png";
import img3 from "../../../assets/img/pamphlets/pamphlets3.png";
import img4 from "../../../assets/img/pamphlets/pamphlets4.png";
import img5 from "../../../assets/img/pamphlets/pamphlets5.png";
import img6 from "../../../assets/img/pamphlets/pamphlets6.png";
import img7 from "../../../assets/img/pamphlets/pamphlets7.png";
import img8 from "../../../assets/img/pamphlets/pamphlets8.png";
import img9 from "../../../assets/img/pamphlets/pamphlets9.png";
import img10 from "../../../assets/img/pamphlets/pamphlets10.png";
import img11 from "../../../assets/img/pamphlets/pamphlets11.png";
import img12 from "../../../assets/img/pamphlets/pamphlets12.png";
import img13 from "../../../assets/img/pamphlets/pamphlets13.png";
import img14 from "../../../assets/img/pamphlets/pamphlets14.png";
import img15 from "../../../assets/img/pamphlets/pamphlets15.png";
import img16 from "../../../assets/img/pamphlets/pamphlets16.png";
import img17 from "../../../assets/img/pamphlets/pamphlets17.png";
import img18 from "../../../assets/img/pamphlets/pamphlets18.png";
import img19 from "../../../assets/img/pamphlets/pamphlets19.png";
import img20 from "../../../assets/img/pamphlets/pamphlets20.png";
import img21 from "../../../assets/img/pamphlets/pamphlets21.png";
import img22 from "../../../assets/img/pamphlets/pamphlets22.png";
import img23 from "../../../assets/img/pamphlets/pamphlets23.png";
import img24 from "../../../assets/img/pamphlets/pamphlets24.png";
import Nbar from "../../nbar/Nbar";

const Pamphlets = () => {
  return (
    <div className={css.wrapper}>
      {" "}
      <Nbar className={css.nav} />
      <div className={css.header}>
        <div className={css.headLeft}>
          <h1>Pamphlets on Sex</h1>
        </div>
        <div className={css.headMiddle}>
          <h6>
            Pamphlets have always been used as a way and means of bringing news
            and views to an eager public - either on a serious, political
            matter, or to put across a tainted and sensationalised account of a
            life. They were especially prevalent as news spreaders before mass
            printing. Here are some examples…..the ones here are those relating
            to sexual practices and themes….elsewhere on the site are pamphlets
            as literature
          </h6>
        </div>
        <div className={css.headRight}>
          <img src={img18plus} alt="" />
        </div>
      </div>
      <div className={css.body}>
        <div className={css.upperBody}>
          <div class={css.gridContainer}>
            <div class={css.gridItem}>
              <img src={img1} alt="" />
              <h1>Pseudo Memoir</h1>
            </div>
            <div class={css.gridItem}>
              <img src={img2} alt="" />
              <h1>Letters Collection</h1>
            </div>
            <div class={css.gridItem}>
              <img src={img3} alt="" /> <h1>Petition</h1>
            </div>
            <div class={css.gridItem}>
              <img src={img4} alt="" /> <h1>A Treatise </h1>
            </div>
            <div class={css.gridItem}>
              <img src={img5} alt="" /> <h1>A Directory of Rich Widowers</h1>
            </div>
            <div class={css.gridItem}>
              <img src={img6} alt="" /> <h1>A Cross Dresser's Life Account</h1>
            </div>
            <div class={css.gridItem}>
              <img src={img7} alt="" /> <h1>A Dying Words Confessional</h1>
            </div>
            <div class={css.gridItem}>
              <img src={img8} alt="" /> <h1>A Sensationalist Warning </h1>
            </div>
            <div class={css.gridItem}>
              <img src={img9} alt="" /> <h1>A Proclamation </h1>
            </div>
            <div class={css.gridItem}>
              <img src={img10} alt="" /> <h1>Syphilis Cure</h1>
            </div>
            <div class={css.gridItem}>
              <img src={img11} alt="" /> <h1>A Saucy Biography</h1>
            </div>
            <div class={css.gridItem}>
              <img src={img12} alt="" /> <h1>List of Covent Garden Whores</h1>
            </div>
            <div class={css.gridItem}>
              <img src={img13} alt="" /> <h1>Petition</h1>
            </div>
            <div class={css.gridItem}>
              <img src={img14} alt="" /> <h1>Account of a Sex Life</h1>
            </div>
            <div class={css.gridItem}>
              <img src={img15} alt="" /> <h1>Moral Propaganda</h1>
            </div>
            <div class={css.gridItem}>
              <img src={img16} alt="" /> <h1>An Account of a Philosopher</h1>
            </div>
            <div class={css.gridItem}>
              <img src={img17} alt="" /> <h1>An Account of a Cross Dresser</h1>
            </div>
            <div class={css.gridItem}>
              <img src={img18} alt="" /> <h1>Trial Account of Homosexuals</h1>
            </div>
          </div>
        </div>

        <div className={css.lowerBody}>
          <div class={css.gridItem}>
            <img src={img19} alt="" />{" "}
            <h1>A Rounded Long Poetic Account of a Flirtation</h1>
          </div>
          <div class={css.gridItem}>
            <img src={img20} alt="" />{" "}
            <h1>A Treatise on the Benefits of Flogging </h1>
          </div>
          <div class={css.gridItem}>
            <img src={img21} alt="" />{" "}
            <h1>An Account on the Night Lives of Covent Garden Whores </h1>
          </div>
          <div class={css.gridItem}>
            <img src={img22} alt="" />{" "}
            <h1>An Apology for The life & Exploits of a Courtesan</h1>
          </div>
          <div class={css.gridItem}>
            <img src={img23} alt="" />{" "}
            <h1>Treatise and Account of a Flogging School</h1>
          </div>
          <div class={css.gridItem}>
            <img src={img24} alt="" />{" "}
            <h1>Gentleman's Magazine Account of London Lif</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pamphlets;
